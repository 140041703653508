import React from "react";
import { styled } from "@mui/material/styles";

// MUI Icons Import
import { Box, Typography } from "@mui/material";

// MUI Icons Import
import { CalendarMonth } from "@mui/icons-material";
import ListIcon from '@mui/icons-material/List';

// Assets Import
import AvatarOne from "../../../../assets/avatars/avatar-one.webp";
import AvatarTwo from "../../../../assets/avatars/avatar-two.webp";
import AvatarThree from "../../../../assets/avatars/avatar-three.webp";
import AvatarFour from "../../../../assets/avatars/avatar-four.webp";

const data = [
    {
        rank: "2",
        img: AvatarTwo,
        name: "Ghazi Muaz",
        price: '$18,000',
    },
    {
        rank: "3",
        img: AvatarThree,
        name: "Rama Ali",
        price: '$15,000',
    },
    {
        rank: "4",
        img: AvatarFour,
        name: "Hammad Asim",
        price: '$12,000',
    },
];

const Rank = styled(Box)({
  borderRadius: "24px",
  backgroundColor: "#f4f6f9",
  padding: "6px 10px",
  display: "flex",
  alignItems: "center",
  gap: "12px",
});

const SeeAllButton = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "4px",
  padding: "4px 10px",
  borderRadius: "4px",
  border: "1px solid #812990",
});

const Leaderboard = () => {
  return (
    <Box sx={{background: "#fff"}} border={"1px solid #ddd"} borderRadius={"10px"} padding={"16px 20px"}>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography fontWeight={"600"} fontSize={"16px"}>
          Leaderboard
        </Typography>
        <Box
          borderRadius={"8px"}
          height={"30px"}
          width={"30px"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          backgroundColor={"rgba(102,45,145,.1)"}
        >
          <CalendarMonth style={{ fontSize: "18px", color: "#662D91" }} />
        </Box>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        gap={"12px"}
        my={4}
      >
        <Typography fontWeight={"600"} color={"#5F41B2"}>
          Most Sales
        </Typography>
        <img
          src={AvatarOne}
          style={{ height: "40px", width: "40px", borderRadius: "20px" }}
          alt="avatar"
        />
        <Typography fontSize={"14px"} fontWeight={"500"}>
          Hannan Asghar
        </Typography>
        <Typography fontSize={"14px"} fontWeight={"700"} color={"primary"}>
          $20,000
        </Typography>
      </Box>
      <Box display={"flex"} flexDirection={"column"} gap={"12px"}>
        {data.map((item) => {
          return (
            <Rank>
              <Typography
                fontWeight={"700"}
                fontSize={"10px"}
                color={"#662D91"}
              >
                {item.rank}
              </Typography>
              <Box flex={1} gap={"4px"} display={"flex"} alignItems={"center"}>
                <img
                  src={item.img}
                  style={{
                    height: "26px",
                    width: "26px",
                    borderRadius: "13px",
                  }}
                  alt="avatar"
                />
                <Typography fontSize={"12px"}>{item.name}</Typography>
              </Box>
              <Typography
                fontWeight={"800"}
                fontSize={"14px"}
                color={"#662D91"}
              >
                {item.price}
              </Typography>
            </Rank>
          );
        })}
      </Box>
      <Box display={"flex"} justifyContent={"center"} mt={2}>
        <SeeAllButton>
          <ListIcon fontSize="12px" style={{color:"#812990"}}/>
          <Typography fontSize={"10px"} color={"#812990"}>See All</Typography>
        </SeeAllButton>
      </Box>
    </Box>
  );
};

export default Leaderboard;
