import React from 'react'
import { styled } from "@mui/material/styles";

// MUI Components Import
import { Box, Grid, Typography, TextField, Button } from '@mui/material';
import { useState } from 'react';

function StockLevel() {

    const [stockLevel, setStockLevel] = useState('')
    const [expiry, setExpiry] = useState('')

    return (
        <>
            <Wrapper mt={3}>
                <Heading>Set a threshold</Heading>
                <Grid container mt={1} spacing={3} display={"flex"} alignItems={"center"} >
                    <Grid item md={4}>
                        <Label>Out of Stock</Label>
                        <Input value={stockLevel} onChange={(e) => setStockLevel(e.target.value)} />
                        <Label sx={{fontSize: "10px"}}>You will be notified when remaining stock is {stockLevel}!</Label>
                    </Grid>
                    <Grid item md={4}>
                        <Label>Expiry</Label>
                        <Input value={expiry} onChange={(e) => setExpiry(e.target.value)} />
                        <Label sx={{fontSize: "10px"}}>You will be notified {expiry} days before!</Label>
                    </Grid>
                    <Grid item md={4}>
                        <SaveBTN>Save</SaveBTN>
                    </Grid>
                    <Grid item md={12}>
                        <Typography sx={{fontSize: "12px", fontWeight: "600", color: "#b9b3b3"}}>*This will be applicable to all products collectively!*</Typography>
                    </Grid>
                </Grid>
            </Wrapper>
        </>
    )
}

const Wrapper = styled(Box)(() => ({
    width: "100%",
    background: "#fff",
    padding: "10px 15px",
    borderRadius: "12px",
    fontSize: "22px",
    fontWeight: "600"
}))

const Heading = styled(Typography)(() => ({
    fontSize: "16px",
    fontWeight: "600",
    color: "#424242"
}))

const Input = styled(TextField)(({
    width: "100%",
    background: "#fff",
    borderRadius: "5px"
}))

const Label = styled(Typography)(() => ({
    fontSize: "12px",
    fontWeight: "500"
}))

const SaveBTN = styled(Button)(({theme}) => ({
    width: '120px', 
    height: '40px', 
    marginTop: "15px",
    fontSize: "16px",
    float: "right",
    background: `${theme.palette.primary.main}`, 
    color: "#fff", 
    borderRadius: '5px',
    textTransform: "capitalize",
    "&:hover": {
        backgroundColor: `${theme.palette.primary.main}`
    }
}))

export default StockLevel