import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';

// MUI Components Import
import { Box, Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography, styled } from '@mui/material';

// Icons Import
import AddPhotoIcon from '@mui/icons-material/AddPhotoAlternate';

// Loader Import
import { MoonLoader } from 'react-spinners';

// React Toastify Imports
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Icons Import
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/BorderColor';
import { baseUrl } from '../../../../../../constants/api';

function Socials() {

	const token = window.localStorage.getItem("user-token");

	const imgUpload = useRef();

	const selectFiles = (index) => {
		imgUpload.current.click();
	};

	const [loading, setLoading] = useState(false)
	const [saving, setSaving] = useState(false);
	const [updating, setUpdating] = useState(false)
	const [name, setName] = useState('')
	const [link, setLink] = useState('')
	const [icon, setIcon] = useState(null)
	const [previewIcon, setPreviewIcon] = useState([]);
	const [socialId, setSocialId] = useState('')
	const [socials, setSocials] = useState([]);

	const handleImageUpload = (e) => {
		const file = e.target.files[0];
		if (file) {
			setIcon(file);
			setPreviewIcon(URL.createObjectURL(file));
		}
	};

	const fetchSocialsData = () => {
		setLoading(true)
		axios.get(`${baseUrl}/api/footer/social`)
			.then((response) => {
				setSocials(response.data)
				setLoading(false)
			})
			.catch(() => {
				setLoading(false)
			});
	};

	const handleSocials = () => {
		const requiredFields = [
			{ value: name, message: "Name is required." },
			{ value: link, message: "Link is required." },
			{ value: icon, message: "Icon is required." },
		];

		for (const field of requiredFields) {
			if (!field.value) {
				toast.error(field.message);
				return;
			}
		}

		setSaving(true);

		const formData = new FormData();
		formData.append("name", name);
		formData.append("link", link);
		if (typeof icon !== "string") {
			formData.append("icon", icon);
		}

		const requestConfig = {
			headers: {
				"Content-Type": "multipart/form-data",
				Authorization: `Token ${token}`,
			},
		};

		const response = !updating
			? axios.post(`${baseUrl}/api/footer/social/create`, formData, requestConfig)
			: axios.put(`${baseUrl}/api/footer/social/${socialId}`, formData, requestConfig);

		response
			.then(() => {
				toast.success("Social Link added successfully!");
				setSaving(false);
				setName("");
				setLink("");
				setIcon(null);
				setPreviewIcon(null);
				fetchSocialsData();
				setUpdating(false);
			})
			.catch((err) => {
				if (err.response) {
					toast.error(err.response.data.link[0] || "An error occurred");
				} else {
					toast.error("An error occurred");
				}
				setSaving(false);
			});
	};

	const editSocial = (social) => {
		setUpdating(true)
		setName(social.name)
		setLink(social.link)
		setIcon(social.icon)
		setPreviewIcon(social.icon)
		setSocialId(social.uuid)
	}

	const handleDelete = (id) => {
		axios.delete(`${baseUrl}/api/footer/social/${id}`,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `Token ${token}`,
				},
			}
		)
			.then(() => {
				toast.success("Social Link deleted successfully!");
				fetchSocialsData()
			})
			.catch((error) => {
				if (error.response) {
					toast.error(error.response.data.message);
				}
			})
	}

	useEffect(() => {
		fetchSocialsData()
	}, [])


	return (
		<>
			<SectionWrapper mt={3}>
				<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
					<Heading>Social Media Links</Heading>
				</Box>
				<Grid container mt={1} spacing={3} display={"flex"} alignItems={"center"}>
					<Grid item md={3}>
						<Label>Social Media Name</Label>
						<Input mt={3} type="text" color='success'
							InputProps={{ sx: { borderRadius: 3 } }}
							value={name} onChange={(e) => setName(e.target.value)}
						/>
					</Grid>
					<Grid item md={3}>
						<Label>Social Media URL</Label>
						<Input mt={3} type="text" color='success'
							InputProps={{ sx: { borderRadius: 3 } }}
							value={link} onChange={(e) => setLink(e.target.value)}
						/>
					</Grid>
					<Grid item md={6} display={"flex"} alignItems={"center"}>
						<Uploader item md={3} onClick={() => selectFiles()}>
							<input type="file" accept="image/*" ref={imgUpload} style={{ display: 'none' }} onChange={(e) => handleImageUpload(e)}
								id="imageInput"
							/>
							<AddPhotoIcon sx={{ fontSize: '25px' }} />
							<Typography sx={{ fontWeight: '500', fontSize: '12px', color: '#2A3F54' }}>Add Image</Typography>
						</Uploader>
						<Grid item md={9}>
							{icon ? (
								<ImgDisplay sx={{ background: `url(${previewIcon}) center/cover no-repeat` }} />
							) : null}
						</Grid>
					</Grid>
				</Grid>
				<Grid item md={11} sx={{ display: "flex", justifyContent: "end" }}>
					<SaveBTN onClick={() => handleSocials()}>
						{saving ? (
							<>
								<MoonLoader color="#fff" size={20} />
							</>
						) : (
							updating ? "update" : "save"
						)}
					</SaveBTN>
				</Grid>
				<TableWrapper component={Paper}>
					<Table>
						<TableHead style={{ backgroundColor: '#DDDDDD' }}>
							<TableRow>
								<TableHeadings>#</TableHeadings>
								<TableHeadings>Icon</TableHeadings>
								<TableHeadings>Name</TableHeadings>
								<TableHeadings>URL</TableHeadings>
								<TableHeadings>Action</TableHeadings>
							</TableRow>
						</TableHead>
						<TableBody>
							{loading ? (
								<TableRow>
									<TableContent sx={{ width: "100%", }} >
										<MoonLoader color="#000" size={20} />
									</TableContent>
								</TableRow>
							) : (
								socials.length === 0 ? (
									<TableRow align="center">
										<TableContent colSpan={5} align="center">
											You have not added any socials yet!
										</TableContent>
									</TableRow>
								) : (
									socials?.map((social, index) => (
										<TableRow key={index}>
											<TableContent sx={{ width: "1%" }} >{index + 1}</TableContent>
											<TableContent sx={{ width: '5%' }}>
												<div style={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
													<img src={social?.icon} alt="category_image"
														style={{ width: '45px', height: '45px', borderRadius: '5px', marginRight: '8px', objectFit: "cover" }}
													/>
												</div>
											</TableContent>
											<TableContent sx={{ width: "30%" }}>{social.name}</TableContent>
											<TableContent sx={{ width: "30%" }}>{social.link}</TableContent>
											<TableContent sx={{ display: "flex", gap: "10px", cursor: "pointer" }}>
												<Tooltip title="edit" placement='top' >
													<EditIcon sx={{ fontSize: "20px", color: "#000" }} onClick={() => editSocial(social)} />
												</Tooltip>
												<Tooltip title="delete" placement='top' >
													<DeleteIcon sx={{ fontSize: "20px", color: "red" }} onClick={() => handleDelete(social.uuid)} />
												</Tooltip>
											</TableContent>
										</TableRow>
									))
								)
							)
							}
						</TableBody>
					</Table>
				</TableWrapper>
			</SectionWrapper>
		</>
	)
}

const SectionWrapper = styled(Box)(() => ({
	width: "100%",
	background: "#fff",
	padding: "10px 15px",
	borderRadius: "12px",
	fontSize: "22px",
	fontWeight: "600"
}))

const Heading = styled(Typography)(() => ({
	fontSize: "16px",
	fontWeight: "600",
	color: "#424242"
}))

const Input = styled(TextField)(({
	width: "100%",
	background: "#fff",
	borderRadius: "5px",
}))

const Label = styled(Typography)(() => ({
	fontSize: "12px",
	fontWeight: "500"
}))

const Uploader = styled(Grid)(() => ({
	display: 'flex',
	flexDirection: "column",
	justifyContent: 'center',
	alignItems: 'center',
	height: "90px",
	cursor: "pointer"
}))

const ImgDisplay = styled(Box)(() => ({
	width: '100px',
	height: '90px',
	backgroundColor: '#fff',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	margin: '10px',
	borderRadius: '10px',
}))

const SaveBTN = styled(Button)(({ theme }) => ({
	width: '120px',
	height: '40px',
	marginTop: "15px",
	fontSize: "16px",
	background: `${theme.palette.primary.main}`,
	color: "#fff",
	borderRadius: '5px',
	textTransform: "capitalize",
	"&:hover": {
		backgroundColor: `${theme.palette.primary.main}`
	}
}))

const TableWrapper = styled(TableContainer)(() => ({
	height: "auto",
	overflow: "auto",
	border: "none",
	boxShadow: "none",
	margin: "30px 0"
}))

const TableHeadings = styled(TableCell)(() => ({
	fontWeight: "600",
	fontSize: "14px",
	lineHeight: "16px",
	color: "black",
	background: "#F2F4F5"
}))

const TableContent = styled(TableCell)(() => ({
	fontWeight: "400",
	fontSize: "12px",
	lineHeight: "16px",
	color: "#71747D",
	border: 'none'
}))

export default Socials;

