import React,{useState} from 'react';
import { styled } from "@mui/material/styles";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// React Toastify Imports
import { toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Loader Import
import { MoonLoader } from 'react-spinners';

// MUI Componenets Import
import { Container, Grid, Box, Typography, TextField, Button, FormControlLabel, Checkbox } from '@mui/material';

// Assets Import
import background from "../../assets/background.webp";
import logo from "../../assets/logo_purple.webp";
import { baseUrl } from '../../constants/api';

function Signin() {

    const navigate = useNavigate()
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [navigating, setNavigating] = useState(false)

    const handleSignin = () => {
        setNavigating(true)
        axios.post(`${baseUrl}/api/auth/login`,
            {
                username: email,
                password: password,
            }
        )
        .then((response) => {
            const { token } = response.data;
            window.localStorage.setItem("user-token", token);
            toast.success("Successfully Logged In!");
            setTimeout(() => {
                setNavigating(false)
                navigate('/dashboard');
            }, 3000);
        })
        .catch((err) => {
            if (err.response) {
                if (err.response.data) {
                    const errorMessages = [];
                    for (const key in err.response.data) {
                        if (Array.isArray(err.response.data[key])) {
                            errorMessages.push(...err.response.data[key]);
                        }
                    }
                    if (errorMessages.length > 0) {
                        toast.error(errorMessages.join("\n"));
                    } else {
                        toast.error("Login Failed! Please try again.");
                    }
                } else {
                    toast.error(err.response.data.message || "Login Failed! Please try again.");
                }
            } else {
                toast.error("An error occurred. Please try again later.");
            }
            setNavigating(false)
        })
    };

    return (
        <Wrapper maxWidth={false}>
            <Grid container display={"flex"} justifyContent={"center"}>
                <FormContainer item lg={8} md={8} sm={8} xs={12}>
                    <FormWrapper>
                        <img src={logo} alt='logo' style={{maxWidth: "100%", height: "100px"}} />
                        <Heading>Sign in to your account</Heading>
                        <Text>Start your demo version</Text>
                        <Box py={2}>
                            <Input type="email" label="Email" fullWidth color="primary" 
                                onChange={(e) => setEmail(e.target.value)} 
                            />
                            <Input type="password" label="Password" fullWidth color="primary" 
                                onChange={(e) => setPassword(e.target.value)} 
                            />
                        </Box>
                        <Box mb={1} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                            <FormControlLabel
                                control={<Checkbox size="small" sx={{ padding: "0 0 0 9px" }} />}
                                label={<Label>Remember me</Label>}
                            />
                            <Label sx={{ cursor: "pointer", color: "#812990" }} onClick={() => navigate('/forgot-password')}>Forgot your password?</Label>
                        </Box>
                        <Button sx={{ width: '100%',textTransform: 'none'}} variant="contained" color="secondary"
                            onClick={() => handleSignin()}
                        >
                            {navigating ? (
                                <>                                    
                                    <MoonLoader color="#fff" size={20} />
                                </>
                            ) : (
                                "Sign In"
                            )}
                        </Button>                       
                        {/* <Box
                            style={{
                                padding: '20px 0 0',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '8px',
                            }}
                        >
                            <Label>Don't have an account?</Label>
                            <Label
                                sx={{ cursor: 'pointer', color: '#812990' }}
                                onClick={() => navigate('/signup')}
                            >
                                Sign up
                            </Label>
                        </Box> */}
                    </FormWrapper>
                </FormContainer>
            </Grid>
        </Wrapper>
    );
}

// Styled Components

const Wrapper = styled(Container)(() => ({
    padding: "0 !important",
    background: `url(${background}) center/cover no-repeat`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
}))

const FormContainer = styled(Grid)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
}))

const FormWrapper = styled(Box)(({ theme }) => ({
    maxWidth: "450px",
    minWidth: "320px",
    padding: "20px 50px",
    textAlign: "center",
    background: 'white',
    borderRadius: '10px',
    [theme.breakpoints.down('sm')]: {
        width: '310px',
        padding: '20px'
    }
}))

const Heading = styled(Typography)(({ theme }) => ({
    fontSize: "18px",
    fontWeight: "700",
    color: "#2A3342",
    [theme.breakpoints.down('sm')]: {
        fontSize: "14px",
    },
}))

const Text = styled(Typography)(({ theme }) => ({
    fontSize: "14px",
    fontWeight: "400",
    color: "#556987",
    [theme.breakpoints.down('sm')]: {
        fontSize: "12px",
    },
}))

const Input = styled(TextField)(() => ({
    width: "100%",
    marginBottom: "15px"
}))

const Label = styled(Typography)(() => ({
    fontSize: "10px",
}))

export default Signin;
