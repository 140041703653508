import React from 'react'
import { styled } from "@mui/material/styles";

// MUI Components Import
import { Grid, Box, Typography } from '@mui/material'

// Recharts Components Import
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

// Icon Import
import FilterListIcon from "@mui/icons-material/FilterList";

// Dummy Data for Charts
const data = [
    {
        month: 'Jan',
        expenses: 18000,
        budget: 20000,
    },
    {
        month: 'Feb',
        expenses: 15000,
        budget: 20000,
    },
    {
        month: 'Mar',
        expenses: 35000,
        budget: 30000,
    },
    {
        month: 'Apr',
        expenses: 32000,
        budget: 30000,
    },
    {
        month: 'May',
        expenses: 28000,
        budget: 30000,
    },
    {
        month: 'Jun',
        expenses: 22500,
        budget: 28000,
    },
    {
        month: 'Jul',
        expenses: 24000,
        budget: 28000,
    },
    {
        month: 'Aug',
        expenses: 22000,
        budget: 25000,
    },
    {
        month: 'Sep',
        expenses: 30000,
        budget: 25000,
    },
    {
        month: 'Oct',
        expenses: 33000,
        budget: 30000,
    },
    {
        month: 'Nov',
        expenses: 37000,
        budget: 35000,
    },
    {
        month: 'Dec',
        expenses: 41850,
        budget: 42000,
    },
];

function BudgetGraph() {
    return (
        <>
            <Grid container my={3}>
                <Card item md={12}>
                    <Box mt={1} mb={2} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                        <Typography fontWeight={"600"} fontSize={"18px"}>Budget, Sales, Expenses & Profits</Typography>
                        <Box alignItems={"center"} display={"flex"} gap={"20px"}>
                            <FilterButton>
                                <FilterListIcon fontSize="12px" />
                                <Typography fontSize={"12px"}>Filter</Typography>
                            </FilterButton>
                        </Box>
                    </Box>
                    <Box width={"100%"} height={"350px"}>
                        <ResponsiveContainer width="100%" height="100%">
                            <LineChart data={data}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="month" tick={{fontSize: "10px"}} />
                                <YAxis tick={{fontSize: "10px"}} />
                                <Tooltip />
                                <Line type="monotone" dataKey="expenses" stroke="#8884d8" activeDot={{ r: 5 }} />
                                <Line type="monotone" dataKey="budget" stroke="#82ca9d" />
                            </LineChart>
                        </ResponsiveContainer>
                    </Box>
                </Card>
            </Grid>
        </>
    )
}

// Styled Components
const Card = styled(Grid)(() => ({
    display: "flex",
    flexDirection: "column",
    background: "#fff",
    padding: "15px 25px",
    borderRadius: "14px",
    border: '1px solid #DDDDDD',
}))
  
const FilterButton = styled(Box)({
    display: "flex",
    alignItems: "center",
    gap: "4px",
    padding: "6px 10px",
    borderRadius: "4px",
    border: "1px solid #ddd",
});

export default BudgetGraph