import React, { useState } from 'react'

import { Box, Button, Grid, Switch, TextField, Typography, styled } from '@mui/material';

function PaymentGateways() {

    const [fatoorahToggle, setFatoorahToggle] = useState(false);
    const [payfortToggle, setPayfortToggle] = useState(false);

    const handleToggleChange = () => {
        setFatoorahToggle(!fatoorahToggle);
    };
    const handlePayfortToggle = () => {
        setPayfortToggle(!payfortToggle);
    };

    return (
        <>
            <SectionWrapper mt={3}>

                <Heading>Payment Gateways</Heading>

                <Grid container mt={1} spacing={1} display={"flex"} justifyContent={"space-between"}>

                    <PaymentCard item md={5.7}  >
                        <CardHeader >
                            <Heading>Fatoorah Payment Gateway</Heading>
                            <Switch
                                checked={fatoorahToggle}
                                onChange={handleToggleChange}
                            />
                        </CardHeader>
                        {fatoorahToggle && (
                            <ActiveCard item md={12}>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'start',
                                    alignItems: 'start',
                                    width: '100%'
                                }}>
                                    <Label>API Key</Label>
                                    <Input />
                                </Box>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'start',
                                    alignItems: 'start',
                                    width: '100%'
                                }}>
                                    <Label>Secret Key</Label>
                                    <Input />
                                </Box>
                                <SaveBTN>Save Keys</SaveBTN>

                            </ActiveCard>
                        )}

                    </PaymentCard>
                    <PaymentCard item md={5.7}  >
                        <CardHeader >
                            <Heading>Payfort Payment Gateway</Heading>
                            <Switch
                                checked={payfortToggle}
                                onChange={handlePayfortToggle}
                            />
                        </CardHeader>
                        {payfortToggle && (
                            <ActiveCard item md={12}>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'start',
                                    alignItems: 'start',
                                    width: '100%'
                                }}>
                                    <Label>API Key</Label>
                                    <Input />
                                </Box>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'start',
                                    alignItems: 'start',
                                    width: '100%'
                                }}>
                                    <Label>Secret Key</Label>
                                    <Input />
                                </Box>
                                <SaveBTN>Save Keys</SaveBTN>

                            </ActiveCard>
                        )}

                    </PaymentCard>



                </Grid>
            </SectionWrapper>
        </>
    )
}

const SectionWrapper = styled(Box)(() => ({
    width: "100%",
    background: "#fff",
    padding: "10px 15px",
    borderRadius: "12px",
    fontSize: "22px",
    fontWeight: "600"
}))

const Heading = styled(Typography)(() => ({
    fontSize: "17px",
    fontWeight: "700",
    color: "#424242",

}));

const Input = styled(TextField)(({
    width: "100%",
    background: "#fff",
    borderRadius: "5px"
}))

const Label = styled(Typography)(() => ({
    fontSize: "12px",
    fontWeight: "500"
}))

const PaymentCard = styled(Grid)(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center',
    border: '1px solid #DDDDDD',
    borderRadius: '10px',
    padding: '10px 20px 10px 20px',
}))
const CardHeader = styled(Grid)(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
}))

const ActiveCard = styled(Grid)(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
    width: '100%',
    gap: '8px'
}))

const SaveBTN = styled(Button)(({ theme }) => ({
    width: '100%',
    height: '40px',
    marginTop: "15px",
    fontSize: "16px",
    float: "right",
    background: `${theme.palette.primary.main}`,
    color: "#fff",
    borderRadius: '5px',
    textTransform: "capitalize",
    "&:hover": {
        backgroundColor: `${theme.palette.primary.main}`
    }
}))


export default PaymentGateways