import { RouterProvider, createBrowserRouter } from "react-router-dom";

// User Theme
import UserTheme from "./UserTheme";

// Entry Pages
import Signin from "./pages/Entry/Signin";
import Signup from "./pages/Entry/Signup";
import ForgotPassword from "./pages/Entry/ForgotPassword";
import ResetPassword from "./pages/Entry/ResetPassword";

import MainDash from "./pages/Dashboards/MainDash"; /* Wrapper for Sidebar & Dashboard Pages */

// Dashboard Pages
import InventoryDash from "./pages/Dashboards/InventoryDash/InventoryDash"; /* Inventory Management Dashboard*/
import ProcSupplyDash from "./pages/Dashboards/ProcSupplyDash/ProcSupplyDash"; /* Procurement & Supply Chain Dashboard*/
import FinancialDash from "./pages/Dashboards/FinancialDash/FinancialDash"; /* Financial Management Dashboard*/
import CRM from "./pages/Dashboards/CRM/CRM"; /* CRM Dashboard */
import HR from "./pages/Dashboards/HR/HR"; /* HR Dashboard*/
import Payments from "./pages/Dashboards/Payments/Payments"; /* Payments Options Selection */
import Delivery from "./pages/Dashboards/Delivery/Delivery"; /* Delivery Options Selection */
import Controls from "./pages/Dashboards/Controls/Controls"; /* Controls Management Page */
import Roles from "./pages/Dashboards/RolesDash/Roles"; /* User Access Management Dashboard */
import Settings from "./pages/Dashboards/Settings/Settings"; /*CMS Dashboard*/

// Inventory Dashboard Pages
import BulkHandling from "./pages/Dashboards/InventoryDash/pages/BulkHandling"; /* Upload/Download CSV Page */
import ProductList from "./pages/Dashboards/InventoryDash/pages/ProductList"; /* Show Products Page */
import ProductDetail from "./pages/Dashboards/InventoryDash/pages/ProductDetail"; /* Product Details Page */
import AddProduct from "./pages/Dashboards/InventoryDash/pages/AddProduct"; /* Add Product Form */
import EditProduct from "./pages/Dashboards/InventoryDash/pages/EditProduct"; /* Add Product Form */
import SalesForecastDetails from "./pages/Dashboards/InventoryDash/pages/SalesForecastDetails";
import DemandForecastDetails from "./pages/Dashboards/InventoryDash/pages/DemandForecastDetails";
import OrdersList from "./pages/Dashboards/InventoryDash/pages/OrdersList"; /* Show orders page */
import OrderDetail from "./pages/Dashboards/InventoryDash/pages/OrderDetail"; /* Single Order Detail page */
import RefundsList from "./pages/Dashboards/InventoryDash/pages/RefundsList"; /* Show Refunds page*/
import Banners from "./pages/Dashboards/Settings/pages/Banners"; /* Upload banners to be reflected on Marketplace*/

// Settings Pages
import ThemeSettings from "./pages/Dashboards/Settings/pages/ThemeSettings";
// import Controls from "./pages/Dashboards/Settings/pages/Controls";

// Controls Pages
import Brands from "./pages/Dashboards/Controls/pages/Brands";
import Models from "./pages/Dashboards/Controls/pages/Models";
import Categories from "./pages/Dashboards/Controls/pages/Categories";
import Subcategories from "./pages/Dashboards/Controls/pages/Subcategories";
import Warehouses from "./pages/Dashboards/Controls/pages/Warehouses";

// Profile page
import Profile from "./pages/Profile/Profile";
import Sales from "./pages/Dashboards/Sales/Sales";
import Variants from "./pages/Dashboards/Controls/pages/Variants";
import Configurations from "./pages/Dashboards/Settings/pages/Configurations";
import Testimonials from "./pages/Dashboards/Controls/pages/Testimonials";
import Footer from "./pages/Dashboards/Settings/pages/Footer/Footer";
import CustomerList from "./pages/Dashboards/Customers/Pages/CustomerList";
import Customers from "./pages/Dashboards/Customers/Customers";
import Reviews from "./pages/Dashboards/InventoryDash/pages/Reviews";

import AuthGuard from "./Auth";
// import ServiceType from "./views/Controls/components/ServiceType";
// import ChargeType from "./views/Controls/components/ChargeType";
import Services from "./views/Services";
import AddService from "./views/Services/components/AddService";
// import ProviderType from "./views/Controls/components/ProviderType";
// import ServiceProviders from "./views/ServiceProviders";
// import ServiceProvidersList from "./views/ServiceProviders/components/ServiceProvidersList";
// import AddServiceProvider from "./views/ServiceProviders/components/AddServiceProvider";
// import Cookies from "./pages/Dashboards/Settings/pages/Footer/Cookies";
// import ServiceCategory from "./views/Controls/components/ServiceCategory";
import LogDetail from "./pages/Dashboards/InventoryDash/pages/LogDetail";
import Units from "./pages/Dashboards/Controls/pages/Units";

const Router = createBrowserRouter([
	{
		path: "/",
		element: <Signin />,
	},
	{
		path: "/signup",
		element: <Signup />,
	},
	{
		path: "/forgot-password",
		element: <ForgotPassword />,
	},
	{
		path: "/reset-password",
		element: <ResetPassword />,
	},
	{
		path: "/dashboard",
		element: (
			<AuthGuard>
				<UserTheme>
					<MainDash />
				</UserTheme>
			</AuthGuard>
		),
		children: [
			{
				path: "",
				element: (
					<AuthGuard>
						<InventoryDash />
					</AuthGuard>
				),
				children: [
					{
						path: "products-list",
						element: (
							<AuthGuard>
								<ProductList />
							</AuthGuard>
						),
					},
					{
						path: "product-detail/:prod_id",
						element: (
							<AuthGuard>
								<ProductDetail />
							</AuthGuard>
						),
					},
					{
						path: "add-product",
						element: (
							<AuthGuard>
								<AddProduct />
							</AuthGuard>
						),
					},
					{
						path: "edit-product/:prod_id",
						element: (
							<AuthGuard>
								<EditProduct />
							</AuthGuard>
						),
					},
					// Services
					{
						path: "services",
						element: (
							<AuthGuard>
								<Services />
							</AuthGuard>
						),
					},
					{
						path: "add-service",
						element: (
							<AuthGuard>
								<AddService />
							</AuthGuard>
						),
					},
					{
						path: "edit-service/:id",
						element: (
							<AuthGuard>
								<AddService />
							</AuthGuard>
						),
					},
					{
						path: "bulk",
						element: (
							<AuthGuard>
								<BulkHandling />
							</AuthGuard>
						),
					},
					{
						path: "log-report",
						element: (
							<AuthGuard>
								<LogDetail />
							</AuthGuard>
						),
					},
					{
						path: "demand-forecast",
						element: (
							<AuthGuard>
								<DemandForecastDetails />
							</AuthGuard>
						),
					},
					{
						path: "sales-forecast",
						element: (
							<AuthGuard>
								<SalesForecastDetails />
							</AuthGuard>
						),
					},
					{
						path: "reviews",
						element: (
							<AuthGuard>
								<Reviews />
							</AuthGuard>
						),
					},
				],
			},
			{
				path: "sales",
				element: (
					<AuthGuard>
						<Sales />
					</AuthGuard>
				),
				children: [
					{
						path: "orders-list",
						element: (
							<AuthGuard>
								<OrdersList />
							</AuthGuard>
						),
					},
					{
						path: "order-detail/:order_id",
						element: (
							<AuthGuard>
								<OrderDetail />
							</AuthGuard>
						),
					},
					{
						path: "refunds-list",
						element: (
							<AuthGuard>
								<RefundsList />
							</AuthGuard>
						),
					},
				],
			},
			{
				path: "customers",
				element: (
					<AuthGuard>
						<Customers />
					</AuthGuard>
				),
				children: [
					{
						path: "customer-list",
						element: (
							<AuthGuard>
								<CustomerList />
							</AuthGuard>
						),
					},
				],
			},
			{
				path: "proc-and-supply",
				element: (
					<AuthGuard>
						<ProcSupplyDash />
					</AuthGuard>
				),
			},
			{
				path: "financials",
				element: (
					<AuthGuard>
						<FinancialDash />
					</AuthGuard>
				),
			},
			{
				path: "crm",
				element: (
					<AuthGuard>
						<CRM />
					</AuthGuard>
				),
			},
			{
				path: "hr",
				element: (
					<AuthGuard>
						<HR />
					</AuthGuard>
				),
			},
			{
				path: "payment",
				element: (
					<AuthGuard>
						<Payments />
					</AuthGuard>
				),
			},
			{
				path: "delivery",
				element: (
					<AuthGuard>
						<Delivery />
					</AuthGuard>
				),
			},
			{
				path: "controls",
				element: (
					<AuthGuard>
						<Controls />
					</AuthGuard>
				),
				children: [
					{
						path: "brands",
						element: (
							<AuthGuard>
								<Brands />
							</AuthGuard>
						),
					},
					{
						path: "models",
						element: (
							<AuthGuard>
								<Models />
							</AuthGuard>
						),
					},
					{
						path: "categories",
						element: (
							<AuthGuard>
								<Categories />
							</AuthGuard>
						),
					},
					{
						path: "subcategories",
						element: (
							<AuthGuard>
								<Subcategories />
							</AuthGuard>
						),
					},
					{
						path: "warehouses",
						element: (
							<AuthGuard>
								<Warehouses />
							</AuthGuard>
						),
					},
					{
						path: "units",
						element: (
							<AuthGuard>
								<Units />
							</AuthGuard>
						),
					},
					{
						path: "variants",
						element: (
							<AuthGuard>
								<Variants />
							</AuthGuard>
						),
					},
					{
						path: "testimonials",
						element: (
							<AuthGuard>
								<Testimonials />
							</AuthGuard>
						),
					},
					// {
					//   path: "service-category",
					//   element: (
					//     <AuthGuard>
					//       <ServiceCategory />
					//     </AuthGuard>
					//   ),
					// },
					// {
					//   path: "service-type",
					//   element: (
					//     <AuthGuard>
					//       <ServiceType />
					//     </AuthGuard>
					//   ),
					// },
					// {
					//   path: "provider-type",
					//   element: (
					//     <AuthGuard>
					//       <ProviderType />
					//     </AuthGuard>
					//   ),
					// },
					// {
					//   path: "charge-type",
					//   element: (
					//     <AuthGuard>
					//       <ChargeType />
					//     </AuthGuard>
					//   ),
					// },
				],
			},
			{
				path: "roles",
				element: (
					<AuthGuard>
						<Roles />
					</AuthGuard>
				),
			},
			{
				path: "settings",
				element: (
					<AuthGuard>
						<Settings />
					</AuthGuard>
				),
				children: [
					{
						path: "banners",
						element: (
							<AuthGuard>
								<Banners />
							</AuthGuard>
						),
					},
					{
						path: "config",
						element: (
							<AuthGuard>
								<Configurations />
							</AuthGuard>
						),
					},
					{
						path: "theme",
						element: (
							<AuthGuard>
								<ThemeSettings />
							</AuthGuard>
						),
					},
					{
						path: "footer",
						element: (
							<AuthGuard>
								<Footer />
							</AuthGuard>
						),
					},
				],
			},
			{
				path: "profile",
				element: (
					<AuthGuard>
						<Profile />
					</AuthGuard>
				),
			},
			// Service Provider
			// {
			//   path: "service-provider",
			//   element: (
			//     <AuthGuard>
			//       <ServiceProviders />
			//     </AuthGuard>
			//   ),
			//   children: [
			//     {
			//       path: "",
			//       element: (
			//         <AuthGuard>
			//           <ServiceProvidersList />
			//         </AuthGuard>
			//       ),
			//     },
			//     {
			//       path: "add",
			//       element: (
			//         <AuthGuard>
			//           <AddServiceProvider />
			//         </AuthGuard>
			//       ),
			//     },
			//     {
			//       path: "edit/:id",
			//       element: (
			//         <AuthGuard>
			//           <AddServiceProvider />
			//         </AuthGuard>
			//       ),
			//     },
			//   ],
			// },
		],
	},
]);

const RootComponent = () => {
	return <RouterProvider router={Router} />;
};

export default RootComponent;
