import { Add, CalendarMonth } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';

const CreateNewBtn = styled("button")({
  borderRadius: 8,
  backgroundColor: "#EFEFEF",
  padding: "8px",
  border: "none",
  color: "#6B6B6B",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize:"10px",
  gap: 6,

  cursor: "pointer",
});

const data = [
    {
        event:"Interview",
        time:"09:30 am - 10:12 am"
    },
    {
        event:"Monthly Meeting",
        time:"09:30 am - 10:12 am"
    },
    {
        event:"Organizational Meeting",
        time:"09:30 am - 10:12 am"
    },
]

const Meetings = () => {
  return (
    <Box sx={{background: "#fff"}} border={"1px solid #ddd"} borderRadius={"10px"} padding={"2px 20px"}>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography fontWeight={"500"} my={1} fontSize={14}>
          Meetings
        </Typography>
        <CreateNewBtn><Add sx={{fontSize:'12px'}}/> Create New</CreateNewBtn>
      </Box>
      <Box>
        {
            data.map((item) => {
                return (
                    <Box display={"flex"} alignItems={"center"} my={2}>
                        {/* Left */}
                        <Box flex={1} display={"flex"} gap={1} alignItems={"center"}>
                        <Box
                            height={"36px"} width={"36px"} display={"flex"} justifyContent={"center"} alignItems={"center"} borderRadius={2} backgroundColor={"#EFEFEF"}
                        >
                          <CalendarMonth style={{ fontSize: "18px" , color:"#333"}} />
                        </Box>

                        <Box display={"flex"} flexDirection={"column"}>
                            <Typography fontSize={"14px"} fontWeight={"600"}>{item.event}</Typography>
                            <Typography color={"#A4A4A4"} fontSize={"10px"}>{item.time}</Typography>
                        </Box>

                        </Box>
                        {/* Right */}
                        <Box backgroundColor={"rgba(102, 45, 145,.1)"} padding={"4px 6px"} borderRadius={"6px"}>
                            <MoreVertIcon sx={{color:"#662D91",fontSize:"14px"}}/>
                        </Box>
                    </Box>

                )
            })
        }
      </Box>
    </Box>
  );
};

export default Meetings;
