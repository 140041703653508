import React, { useState } from 'react'
import { styled } from "@mui/material/styles";
import axios from 'axios';

// MUI Components Import
import { Box, Grid, Typography, TextField, Button } from '@mui/material';

// Loader Import
import { MoonLoader } from 'react-spinners';

// React Toastify Imports
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { baseUrl } from '../../../../constants/api';

function WarehouseControl() {

    const token = window.localStorage.getItem("user-token");

    const [saving, setSaving] = useState(false)
    const [warehouseName, setWarehouseName] = useState("")
    const [warehouseLocation, setWarehouseLocation] = useState("")

    const handleCreate = () => {
        setSaving(true)
        axios.post(`${baseUrl}/api/warehouse/create`,
            {
                name: warehouseName,
                location: warehouseLocation
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${token}`,
                },
            }
        )
            .then((response) => {
                toast.success("Warehouse created successfully!");
                setTimeout(() => {
                    setSaving(false)
                }, 2000);
                setWarehouseName("")
                setWarehouseLocation("")
            })
            .catch((err) => {
                toast.error("Failed to create Warehouse!");
                setSaving(false)
            })
    }

    return (
        <>
            <Wrapper mt={3}>
                <Heading>Create a Warehouse</Heading>
                <Grid container mt={1} spacing={3} display={"flex"} alignItems={"center"} >
                    <Grid item md={4}>
                        <Label>Warehouse Name</Label>
                        <Input placeholder='Warehouse name' value={warehouseName} onChange={(e) => setWarehouseName(e.target.value)} />
                    </Grid>
                    <Grid item md={4}>
                        <Label>Warehouse Location</Label>
                        <Input placeholder='Warehouse Location' value={warehouseLocation} onChange={(e) => setWarehouseLocation(e.target.value)} />
                    </Grid>
                    <Grid item md={4}>
                        <SaveBTN onClick={() => handleCreate()}>
                            {saving ? (
                                <>
                                    <MoonLoader color="#fff" size={20} />
                                </>
                            ) : (
                                "Create"
                            )}
                        </SaveBTN>
                    </Grid>
                </Grid>
            </Wrapper>
        </>
    )
}

const Wrapper = styled(Box)(() => ({
    width: "100%",
    background: "#fff",
    padding: "10px 15px",
    borderRadius: "12px",
    fontSize: "22px",
    fontWeight: "600"
}))

const Heading = styled(Typography)(() => ({
    fontSize: "16px",
    fontWeight: "600",
    color: "#424242"
}))

const Input = styled(TextField)(({
    width: "100%",
    background: "#fff",
    borderRadius: "5px"
}))

const Label = styled(Typography)(() => ({
    fontSize: "12px",
    fontWeight: "500"
}))

const SaveBTN = styled(Button)(({ theme }) => ({
    width: '120px',
    height: '40px',
    marginTop: "15px",
    fontSize: "16px",
    float: "right",
    background: `${theme.palette.primary.main}`,
    color: "#fff",
    borderRadius: '5px',
    textTransform: "capitalize",
    "&:hover": {
        backgroundColor: `${theme.palette.primary.main}`
    }
}))

export default WarehouseControl