import React from 'react'
import { styled } from "@mui/material/styles";

// MUI Components Import
import { Container, Grid } from '@mui/material'

// Componenets Import
import DashboardCards from '../InventoryDash/components/DashboardCards';
import DefectedItems from './components/DefectedItems';
import OverduePayments from './components/OverduePayments';
import PipelineProcess from './components/PipelineProcess';
import SoleVendor from './components/SoleVendor';
import Replenishment from './components/Replenishment';
import ResolutionTime from './components/ResolutionTime';

// Assets Import
import iconOne from '../../../assets/card-icon-one.webp'
import iconTwo from '../../../assets/card-icon-two.webp'
import iconThree from '../../../assets/card-icon-three.webp'
import iconFour from '../../../assets/card-icon-four.webp'
import Header from '../components/Header';

const headerData = {
    heading: "Procurement and Supply Chain",
    subheading: "Optimizing end-to-end sourcing, procurement, and distribution for seamless supply chain efficiency",
}

const cardData = [
    {
        backgroundColor: "#FFF2E5",
        icon: iconOne,
        heading: "Highest Lead Time",
        number: "2 Days",
    },
    {
        backgroundColor: "#FFDFF6",
        icon: iconTwo,
        heading: "POs in Process",
        number: "2920",
    },
    {
        backgroundColor: "#DDFFE7",
        icon: iconThree,
        heading: "Pending POs",
        number: "540",
    },
    {
        backgroundColor: "#FFEBEA",
        icon: iconFour,
        heading: "Total No of Vendors",
        number: "540",
    },
];

function Dashboard() {
    return (
        <>
            <Wrapper maxWidth={false}>
                <Header data={headerData} />
                <DashboardCards data={cardData} />
                <Grid container my={3} sx={{display: "flex", justifyContent: "space-between"}}>
                    <DefectedItems />
                    <OverduePayments />
                </Grid>
                <Grid container my={3} sx={{display: "flex", justifyContent: "space-between"}}>
                    <PipelineProcess />
                    <SoleVendor />
                </Grid>
                <Grid container my={3} sx={{display: "flex", justifyContent: "space-between"}}>
                    <Replenishment />
                    <ResolutionTime />
                </Grid>
            </Wrapper>
        </>
    )
}

// Styled Components

const Wrapper = styled(Container)(() => ({
    padding: "1rem",
	width: "100%",
	height: "calc(100vh - 60px)",
    overflow: "auto"
}))

export default Dashboard