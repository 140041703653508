import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

// MUI Components Import
import { Container, Box, Grid, Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, MenuItem, Select } from "@mui/material";

// import icons
import Layers from '@mui/icons-material/Layers';

// Components Import
import Loader from '../../../Loaders/Loader';

// Loader Import
import { MoonLoader } from 'react-spinners';

// React Toastify Imports
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { baseUrl } from "../../../../constants/api";

const OrderDetail = () => {

    const { order_id } = useParams()

    const token = window.localStorage.getItem("user-token");

    const [loading, isLoading] = useState(false);
    const [downloading, isDownloading] = useState(false);
    const [detail, setDetail] = useState();
    const [status, setStatus] = useState();
    const [isEditing, setIsEditing] = useState(false);

    const fetchOrderDetails = (id) => {
        isLoading(true)
        axios.get(`${baseUrl}/api/orders/${id}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${token}`,
                },
            }
        )
            .then((response) => {
                setDetail(response.data)
                setStatus(response.data.order.status)
                isLoading(false)
            })
            .catch((error) => {
                console.error("Error fetching order details:", error);
                isLoading(false)
            });
    }

    const getStatusText = (status) => {
        switch (status) {
            case 1:
                return "Order Placed";
            case 2:
                return "Accepted";
            case 3:
                return "Rejected";
            case 4:
                return "Dispatched";
            case 5:
                return "Delivered";
            default:
                return "Unknown";
        }
    };

    const getStatus = (status) => {
        isLoading(true)
        axios.put(`${baseUrl}/api/order/status/${order_id}`,
            {
                status: status
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${token}`,
                },
            }
        )
            .then((response) => {
                fetchOrderDetails(order_id)
                isLoading(false)
            })
            .catch((error) => {
                console.log(error)
                isLoading(false)
            })
    }

    const downloadInvoice = (id) => {
        isDownloading(true)
        axios.get(`${baseUrl}/api/invoice/${id}`, {
            responseType: 'blob', // Set the response type to blob to handle binary data
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`,
            },
        })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `invoice_${id}.pdf`);
                document.body.appendChild(link);
                link.click();
                toast.success("Invoice PDF Downloaded!");
                isDownloading(false);
            })
            .catch(error => {
                toast.error("Error downloading Invoice!");
                isDownloading(false);
            });
    }

    useEffect(() => {
        // Call the function to fetch order details with the ordId
        fetchOrderDetails(order_id);
    }, []);

    const handleCencle = () => {
        isLoading(true)
        axios.post(`${baseUrl}/api/shipping/cancel/${detail?.order_shipment?.tracking_number}`,
            {},
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${token}`,
                },
            }
        )
            .then((response) => {
                toast.success("Shippment successfully cancel!");
                setTimeout(() => {
                    isLoading(false)
                }, 2000);

            })
            .catch((err) => {
                toast.error("Failed to cancel the shippment!");
                isLoading(false)
            })
    }

    const data = [
        { Heading: 'Order ID:', OrderData: `${detail?.order.ordId}` },
        { Heading: 'Full Name:', OrderData: `${detail?.profile.fullname}` },
        { Heading: 'Email:', OrderData: `${detail?.profile.user_email}` },
        { Heading: 'Delivery Address:', OrderData: `${detail?.order.shipping_address}` },
        { Heading: 'Phone:', OrderData: `${detail?.profile.phone_number}` },
        { Heading: 'Total Products:', OrderData: `${detail?.products.length}` },
        { Heading: 'Created at:', OrderData: `${new Date(detail?.order.created_at).toLocaleString()}` },
        { Heading: 'OrderNotes:', OrderData: `${detail?.order?.notes ? detail?.order?.notes : "None"}` },
    ];

    const shipping = [
        { Heading: 'Tracking no:', OrderData: `${detail?.order_shipment?.tracking_number}` },
        { Heading: 'Pickup Address:', OrderData: `${detail?.order_shipment?.raw?.collection_address}` },
        { Heading: 'Pickup Contact no:', OrderData: `${detail?.order_shipment?.raw?.collection_phone}` },
        { Heading: 'Delivery Address:', OrderData: `${detail?.order_shipment?.raw?.delivery_address}` },
        { Heading: 'Delivery Contact no:', OrderData: `${detail?.order_shipment?.raw?.delivery_phone}` },
        { Heading: 'Delivery Person Name:', OrderData: `${detail?.order_shipment?.raw?.delivery_name}` },
    ];



    const handleChange = (event) => {
        setStatus(event.target.value);
    };

    const handleUpdate = () => {
        getStatus(status, order_id, token, isLoading, fetchOrderDetails);
        setIsEditing(false);
    };

    return (
        <>
            <Wrapper maxWidth={false}>
                {loading ?
                    <Loader />
                    :
                    <Grid container my={1} gap={'20px'} justifyContent={'space-between'} display={'flex'} >
                        <Grid item md={4.7} sx={{ display: "flex", flexDirection: "row", alignItems: 'center', gap: "8px" }}>
                            <Typography
                                sx={{
                                    color: "#191C1F",
                                    fontSize: "16px",
                                    fontWeight: 'bold',
                                }}
                            >
                                Order Status:
                                {isEditing ? (
                                    <Select
                                        value={status}
                                        onChange={handleChange}
                                        sx={{
                                            marginLeft: 1,
                                            height: '40px',
                                            minHeight: '30px',
                                            '& .MuiSelect-select': {
                                                paddingTop: '4px',
                                                paddingBottom: '4px',
                                            },

                                        }}
                                    >
                                        <MenuItem value={1}>Order Placed</MenuItem>
                                        <MenuItem value={2}>Accepted</MenuItem>
                                        <MenuItem value={3}>Rejected</MenuItem>
                                        <MenuItem value={4}>Dispatched</MenuItem>
                                        <MenuItem value={5}>Delivered</MenuItem>
                                    </Select>
                                ) : (
                                    <span styled={{
                                        color: "#191C1F",
                                        fontSize: "16px",
                                        fontWeight: 'bold',
                                    }}> {getStatusText(status)}</span>
                                )}
                            </Typography>
                            {isEditing ? (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleUpdate}
                                    sx={{ marginLeft: 1 }}
                                >
                                    Update
                                    {/* {isLoading ? <CircularProgress size={24} /> : 'Update'} */}
                                </Button>
                            ) : (
                                status === 5 && (
                                    <Button
                                        variant="text"
                                        onClick={() => setIsEditing(true)}
                                        sx={{ marginLeft: 1 }}
                                    >
                                        Edit
                                    </Button>
                                )
                            )}

                        </Grid>
                        <Grid item md={6.2} sx={{ display: "flex", flexDirection: "row", justifyContent: 'flex-end', gap: "8px" }}>
                            {detail?.order.status === 1 ? (
                                <>
                                    <Button size="small" style={{ textTransform: "none", borderRadius: "24px" }} variant="outlined" color="success"
                                        backgroundcolor={"green"} startIcon={<Layers />} onClick={() => getStatus(2)}
                                    >
                                        Accept
                                    </Button>
                                    <Button size="small" style={{ textTransform: "none", borderRadius: "24px" }} variant="outlined" marginleft={5}
                                        color="error" startIcon={<Layers />} onClick={() => getStatus(3)}
                                    >
                                        Reject
                                    </Button>
                                    <Button size="small" style={{ textTransform: "none", borderRadius: "24px" }} variant="outlined" color="warning"
                                        backgroundcolor={"green"} startIcon={<Layers />} onClick={() => getStatus(4)}
                                    >
                                        Dispatch
                                    </Button>
                                    <Button size="small" style={{ textTransform: "none", borderRadius: "24px" }} variant="outlined" color="primary"
                                        backgroundcolor={"green"} startIcon={<Layers />} onClick={() => getStatus(5)}
                                    >
                                        Deliver
                                    </Button>
                                </>
                            ) : detail?.order.status === 2 ? (
                                <>
                                    <Button size="small" style={{ textTransform: "none", borderRadius: "24px" }} variant="outlined" color="warning"
                                        backgroundcolor={"green"} startIcon={<Layers />} onClick={() => getStatus(4)}
                                    >
                                        Dispatch
                                    </Button>
                                    <Button size="small" style={{ textTransform: "none", borderRadius: "24px" }} variant="outlined" color="primary"
                                        backgroundcolor={"green"} startIcon={<Layers />} onClick={() => getStatus(5)}
                                    >
                                        Deliver
                                    </Button>
                                </>
                            ) : detail?.order.status === 4 ? (
                                <Button size="small" style={{ textTransform: "none", borderRadius: "24px" }} variant="outlined" color="primary"
                                    backgroundcolor={"green"} startIcon={<Layers />} onClick={() => getStatus(5)}
                                >
                                    Deliver
                                </Button>
                            ) : null
                            }
                        </Grid>
                        <Grid item md={12} display={'flex'} flexDirection={'row'} gap={'20px'}>
                            <Grid item md={8} sx={{ border: ' 1px solid #DDDDDD', borderRadius: "8px", height: 'auto', background: "#fff" }}>
                                <Box sx={{ borderBottom: '1px solid #E4E7E9', padding: '10px' }}>
                                    <Typography
                                        sx={{
                                            color: "#191C1F",
                                            fontSize: "16px",
                                            fontWeight: 'bold',

                                        }}>
                                        Order Details
                                    </Typography>
                                </Box>
                                <Box display={'flex'} flexDirection={'column'} padding={'0px 10px 20px'} marginTop={'10px'} gap={"15px"}>
                                    <Grid container spacing={0} sx={{ gap: '12px' }}>
                                        {data.map((row, index) => (
                                            <Grid item container key={index} >
                                                <Grid item xs={3} >
                                                    <Box >
                                                        <Typography sx={{ fontSize: "14px", fontWeight: "bold", textAlign: 'left' }}>{row.Heading}</Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={6} >
                                                    <Box >
                                                        <Typography sx={{ fontSize: "14px", color: '#5F6C72' }}>{row.OrderData}</Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </Grid>

                                </Box>
                            </Grid>

                            <Grid item md={4} sx={{ border: ' 1px solid #DDDDDD', borderRadius: "8px", height: 'auto', background: "#fff" }}>
                                <Typography
                                    sx={{
                                        color: "#191C1F",
                                        fontSize: "16px",
                                        fontWeight: 'bold',
                                        padding: '15px 10px'
                                    }}
                                >
                                    Payment Details
                                </Typography>

                                <Box display={'flex'} flexDirection={'column'} padding={'0px 10px 20px'}>
                                    <Box display={'flex'} justifyContent={'space-between'}>
                                        <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>Sub-total</Typography>
                                        <Typography sx={{ fontSize: "14px", color: '#5F6C72' }}>{parseFloat(detail?.order.total_price || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} SR</Typography>
                                    </Box>
                                    {/* <Box mt={'8px'} display={'flex'} justifyContent={'space-between'}>
                                        <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>Shipping</Typography>
                                        <Typography sx={{ fontSize: "14px", color: '#5F6C72'}}>{detail?.order.shipping}</Typography>
                                    </Box> */}
                                    <Box mt={'8px'} display={'flex'} justifyContent={'space-between'}>
                                        <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>Discount</Typography>
                                        <Typography sx={{ fontSize: "14px", color: '#5F6C72' }}>0</Typography>
                                    </Box>
                                    {/* <Box mt={'8px'} display={'flex'} justifyContent={'space-between'}>
                                        <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>Vat</Typography>
                                        <Typography sx={{ fontSize: "14px", color: '#5F6C72'}}>{detail?.order.tax}</Typography>
                                    </Box> */}
                                    <Box mt={'20px'} display={'flex'} justifyContent={'space-between'} borderTop={'1px solid #DDDDDD'}>
                                        <Typography sx={{ fontSize: "14px", color: 'black', fontWeight: 'bold', mt: '10px' }}>Total</Typography>
                                        <Typography sx={{ fontSize: "14px", fontWeight: "bold", mt: '10px' }}>{parseFloat(detail?.order.total_price || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} SR</Typography>
                                    </Box>
                                </Box>
                                <Box mt={2} mb={4} gap={2}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Button
                                        size="small"
                                        style={{ textTransform: "none", padding: "4px 24px", width: '80%', height: "40px", borderRadius: "24px" }}
                                        color="secondary"
                                        variant="contained"
                                        onClick={() => downloadInvoice(detail?.order.ordId)}
                                    >
                                        {downloading ? (
                                            <>
                                                <MoonLoader color="#fff" size={20} />
                                            </>
                                        ) : (
                                            "Download Invoice"
                                        )}
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item md={12} display={'flex'} flexDirection={'row'} gap={'20px'}>
                            <Grid item md={12} sx={{ border: ' 1px solid #DDDDDD', borderRadius: "8px", height: 'auto', background: "#fff" }}>
                                <Box sx={{
                                    borderBottom: '1px solid #E4E7E9', padding: '10px',
                                    display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: 'center'
                                }}>
                                    <Typography
                                        sx={{
                                            color: "#191C1F",
                                            fontSize: "16px",
                                            fontWeight: 'bold',

                                        }}>
                                        Shipping Detail
                                    </Typography>
                                    {detail?.order_shipment?.tracking_number !== "" ? (
                                        <Typography
                                            sx={{
                                                color: "#191C1F",
                                                fontSize: "16px",
                                                fontWeight: 'bold',

                                            }}>
                                            Shippment Status: {detail?.order_shipment?.raw?.status_label}
                                        </Typography>
                                    )
                                        : ("")}
                                </Box>
                                <Box display={'flex'} flexDirection={'column'} padding={'0px 10px 20px'} marginTop={'10px'} gap={"15px"}>
                                    {detail?.order_shipment?.tracking_number !== "" ? (
                                        <Grid container spacing={0} sx={{ gap: '12px' }}>
                                            {shipping?.map((row, index) => (
                                                <Grid item container key={index}>
                                                    <>
                                                        <Grid item xs={3}>
                                                            <Box>
                                                                <Typography sx={{ fontSize: "14px", fontWeight: "bold", textAlign: 'left' }}>{row.Heading}</Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Box>
                                                                <Typography sx={{ fontSize: "14px", color: '#5F6C72' }}>{row.OrderData}</Typography>
                                                            </Box>
                                                        </Grid>
                                                    </>

                                                </Grid>
                                            ))}
                                            {status === 2 ? (
                                                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end', paddingInline: '20px' }}  >
                                                    {detail?.order_shipment?.raw?.status_label != "Cancelled" ? (
                                                        <Button aria-label="search" variant="contained" color="error"

                                                            onClick={() => { handleCencle() }}
                                                        >
                                                            Cancel the shippment
                                                        </Button>

                                                    ) : ("")}



                                                </Grid>
                                            ) : ("")}

                                        </Grid>
                                    ) : (
                                        <Typography sx={{ fontSize: "14px", fontWeight: "bold", textAlign: 'center' }}> Your order hasn't been accepted yet!</Typography>
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item md={12} sx={{ border: ' 1px solid #DDDDDD', borderRadius: "8px", height: 'auto', background: "#fff" }}>
                            <Box sx={{ borderBottom: '1px solid #E4E7E9', padding: '10px' }}>
                                <Typography
                                    sx={{
                                        color: "#191C1F",
                                        fontSize: "16px",
                                        fontWeight: 'bold',

                                    }}>
                                    Products
                                </Typography>
                            </Box>
                            <TableWrapper component={Paper}>
                                <Table>
                                    <TableHead style={{ backgroundColor: '#DDDDDD' }}>
                                        <TableRow>
                                            <TableHeadings>#</TableHeadings>
                                            <TableHeadings>Name</TableHeadings>
                                            <TableHeadings>Category</TableHeadings>
                                            <TableHeadings>Brand</TableHeadings>
                                            <TableHeadings>Price</TableHeadings>
                                            <TableHeadings>Quantity</TableHeadings>
                                            <TableHeadings>Total</TableHeadings>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {detail?.products.map((item, index) => (
                                            <TableRow key={index}>
                                                <TableContent>{index + 1}</TableContent>
                                                <TableContent sx={{ width: 'auto', display: 'flex', alignItems: 'center', gap: '2px' }}>
                                                    <img src={item?.product.images[0].image} alt="product_image"
                                                        style={{ width: '45px', height: '45px', borderRadius: '5px', marginRight: '8px' }}
                                                    />
                                                    <Typography sx={{ fontSize: "14px", color: '#5F6C72' }}>
                                                        {item?.product.commons.en.productName} , {item?.product.commons.ar.productName}
                                                    </Typography>
                                                </TableContent>
                                                <TableContent sx={{ color: 'black', width: '15%', fontSize: '12px' }} >{item?.product.productCategory} / {item?.product.productSubcategory}</TableContent>
                                                <TableContent sx={{ color: 'black', width: '15%', fontSize: '12px' }} >{item?.product.brand}</TableContent>
                                                <TableContent sx={{ color: 'black', width: '15%', fontSize: '12px' }} >{item?.amount} SAR</TableContent>
                                                <TableContent >{item?.quantity}</TableContent>
                                                <TableContent sx={{ color: 'black', fontSize: '12px' }} >{parseFloat(item?.product.vat_onlinePrice * item?.quantity || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} SR</TableContent>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableWrapper>
                        </Grid>
                    </Grid>
                }
            </Wrapper>


        </>
    );
};

// Styled Components

const Wrapper = styled(Container)(() => ({
    padding: "1rem",
    width: "100%",
    height: "calc(100vh - 60px)",
    overflow: "auto",
}))

// const Wrapper = styled(Box)({
//     marginTop: '20px',
//     backgroundColor: 'white',
//     padding: '20px'
// });

const CardHeading = styled(Typography)(() => ({
    fontSize: "16px",
    fontWeight: "600",
    padding: "12px 15px",
}))
const TableWrapper = styled(TableContainer)(() => ({
    height: "auto",
    overflow: "auto",
    boxShadow: "none",

}))

const TableHeadings = styled(TableCell)(() => ({
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "16px",
    color: "black",
    background: "#F2F4F5"
}))

const TableContent = styled(TableCell)(() => ({
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "16px",
    color: "#71747D",
    border: 'none'
}))

export default OrderDetail;
