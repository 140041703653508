import React from 'react'
import { styled } from "@mui/material/styles";

// MUI Components Import
import { Grid, Typography} from '@mui/material'
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
} from "@mui/material";

function PipelineProcess() {
    return (
        <>
            <Card item md={8.2}>
                <CardHeading>RFPs in Pipeline & RFQs in Process</CardHeading>
                <TableWrapper component={Paper} > 
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableHeadings>Requester Name</TableHeadings>
                                <TableHeadings>RFPs</TableHeadings>
                                <TableHeadings>RFQs</TableHeadings>
                                <TableHeadings>Date</TableHeadings>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableContent>Brian Baker</TableContent>
                                <TableContent>Pipeline</TableContent>
                                <TableContent>Process</TableContent>
                                <TableContent>Sep 1, 2023</TableContent>
                            </TableRow>
                            <TableRow>
                                <TableContent>Patty O'Furniture</TableContent>
                                <TableContent>Pipeline</TableContent>
                                <TableContent>-</TableContent>
                                <TableContent>Aug 31, 2023</TableContent>
                            </TableRow>
                            <TableRow>
                                <TableContent>Olive Yew</TableContent>
                                <TableContent>-</TableContent>
                                <TableContent>Process</TableContent>
                                <TableContent>Aug 29, 2023</TableContent>
                            </TableRow>
                            <TableRow>
                                <TableContent>Maureen Biologist</TableContent>
                                <TableContent>Pipeline</TableContent>
                                <TableContent>-</TableContent>
                                <TableContent>Aug 28, 2023</TableContent>
                            </TableRow>
                            <TableRow>
                                <TableContent>Allie Grater</TableContent>
                                <TableContent>-</TableContent>
                                <TableContent>-</TableContent>
                                <TableContent>Aug 28, 2023</TableContent>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableWrapper>
            </Card>
        </>
    )
}

// Styled Componenets

const Card = styled(Grid)(() => ({
    display: "flex",
    flexDirection: "column",
    background: "#fff",
    borderRadius: "14px",
    border: "1px solid #ddd"
}))

const CardHeading = styled(Typography)(() => ({
    fontSize: "16px",
    fontWeight: "600",
    padding: "12px 24px",
}))

const TableWrapper = styled(TableContainer)(() => ({
	height: "auto",
	overflow: "auto",
	border: "none",
	boxShadow: "none"
}))

const TableHeadings = styled(TableCell)(() => ({
	fontWeight: "600",
	fontSize: "14px",
	lineHeight: "16px",
	color: "#A3AED0",
    background: "#F7F9FB"
}))

const TableContent = styled(TableCell)(() => ({
	fontWeight: "400",
	fontSize: "12px",
	lineHeight: "16px",
	color: "#2B3674"
}))

export default PipelineProcess