import { styled } from "@mui/material/styles";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useParams } from "react-router-dom";

// Datepicker Library Imports
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";

// Tags input component
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";

// MUI Components Import
import {
  Button,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
  Switch,
  Box,
} from "@mui/material";

// Loader Import
import { MoonLoader } from "react-spinners";

// React Toastify Imports
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Components import
import Header from "../../components/Header";
import Dropdown from "../components/Dropdown";
import FormattedInput from "../components/FormattedInput";
import ImgUploader from "../components/ImgUploader";
import InputField from "../components/InputField";
import ProductWarehouses from "../components/ProductWarehouses";

// Modals Import
import { baseUrl } from "../../../../constants/api";
import Loader from "../../../Loaders/Loader";
import BrandModal from "../components/Modals/BrandModal";
import CategoryModal from "../components/Modals/CategoryModal";
import ModelModal from "../components/Modals/ModelModal";
import SubcategoriesModal from "../components/Modals/SubcategoriesModal";
import WarehouseModal from "../components/Modals/WarehouseModal";

function EditProduct() {
  const token = window.localStorage.getItem("user-token");
  const location = useLocation();
  const { serviceScreen } = (location && location?.state) || {};

  const { prod_id } = useParams();
  const navigate = useNavigate();

  const headerData = {
    heading: serviceScreen ? "Edit Service" : "Edit Product",
    subheading: "",
  };

  const [navigating, setNavigating] = useState(false);
  const [loading, isLoading] = useState(false);

  const [brandsList, setBrandsList] = useState([]);
  const [modelsList, setModelsList] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [subcategoriesList, setSubcategoriesList] = useState([]);
  const [unitsList, setUnitsList] = useState([]);
  const [warehousesList, setWarehousesList] = useState([]);
  const [productTypesList, setProductTypesList] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [variantsList, setVariantsList] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [selectedDropdownType, setSelectedDropdownType] = useState(null);
  const [brandModalOpen, setBrandModalOpen] = useState(false);
  const [modelModalOpen, setModelModalOpen] = useState(false);
  const [categoryModalOpen, setCategoryModalOpen] = useState(false);
  const [subcategoryModalOpen, setSubcategoryModalOpen] = useState(false);
  const [warehouseModalOpen, setWarehouseModalOpen] = useState(false);

  const [productName, setProductName] = useState("");
  const [arabicProductName, setArabicProductName] = useState("");
  const [productBarcode, setProductBarcode] = useState("");
  const [productQuantity, setProductQuantity] = useState("");
  const [brand, setBrand] = useState("");
  const [productType, setProductType] = useState("");
  const [model, setModel] = useState("");
  const [productCategory, setProductCategory] = useState("");
  const [productSubcategory, setProductSubcategory] = useState("");
  const [uom, setUom] = useState("");
  const [minQty, setMinQty] = useState("");
  const [orderLimit, setOrderLimit] = useState("");
  const [purchasePrice, setPurchasePrice] = useState("");
  const [sellingPrice, setSellingPrice] = useState("");
  const [onlinePrice, setOnlinePrice] = useState("");
  const [code, setCode] = useState("");
  const [arabicDesc, setArabicDesc] = useState("");
  const [origin, setOrigin] = useState("");
  const [videoURL, setVideoURL] = useState("");
  const [priceValidity, setPriceValidity] = useState(null);
  const [discountType, setDiscountType] = useState(0);
  const [discountValue, setDiscountValue] = useState("");
  const [reorderLevel, setReorderlevel] = useState("");
  const [glaccount, setGlaccount] = useState("");
  const [description, setDescription] = useState("");
  const [serviceTerms, setServiceTerms] = useState("");
  const [arabicServiceTerms, setArabicServiceTerms] = useState("");
  const [productImages, setProductImages] = useState([]);
  const [tags, setTags] = useState([]);
  const [weight, setWeight] = useState("");

  const [VHeight, setVHeight] = useState("");
  const [VWidth, setVWidth] = useState("");
  const [VLength, setVLength] = useState("");
  const [volume, setVolume] = useState("");

  const [psId, setPsid] = useState("");
  const [productWarehouses, setProductWarehouses] = useState([]);

  const [isSwitchOn, setIsSwitchOn] = useState(
    discountType !== 0 ? true : false
  );

  const handleTagsChange = (newTags) => {
    setTags(newTags);
  };

  const calculateVolume = () => {
    const height = parseFloat(VHeight);
    const width = parseFloat(VWidth);
    const length = parseFloat(VLength);

    if (!isNaN(height) && !isNaN(width) && !isNaN(length)) {
      const calculatedVolume = height * width * length;
      setVolume(calculatedVolume.toFixed(2)); // Display volume with 2 decimal places
    } else {
      setVolume(0); // Reset volume if any of the inputs are not valid numbers
    }
  };

  const handleAddClick = (dropdownType) => {
    setSelectedDropdownType(dropdownType);

    // Open the corresponding modal based on the dropdown type.
    switch (dropdownType) {
      case "Brand":
        setBrandModalOpen(true);
        break;
      case "Model":
        setModelModalOpen(true);
        break;
      case "Category":
        setCategoryModalOpen(true);
        break;
      case "Subcategory":
        setSubcategoryModalOpen(true);
        break;
      default:
        break;
    }
  };

  const handleCloseModals = () => {
    setBrandModalOpen(false);
    setModelModalOpen(false);
    setCategoryModalOpen(false);
    setSubcategoryModalOpen(false);
    setWarehouseModalOpen(false);
    setSelectedDropdownType(null);
  };

  const handleImagesChange = (newImages) => {
    setProductImages((prevImages) => [...prevImages, ...newImages]);
  };

  const handleWarehouseChanges = (newwarehouses) => {
    setProductWarehouses(newwarehouses);
  };

  if (productQuantity != null) {
    var pq = productQuantity.replace(/,/g, "");
  }
  if (purchasePrice || sellingPrice || onlinePrice) {
    var mq = minQty.replace(/,/g, "");
    var ol = orderLimit.replace(/,/g, "");
    var rol = reorderLevel.replace(/,/g, "");
    var pp = purchasePrice.replace(/,/g, "");
    var sp = sellingPrice.replace(/,/g, "");
    var op = onlinePrice.replace(/,/g, "");
  }

  const fetchEntities = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/entities`, {
        headers: {
          "Content-Type": "text/plain",
          Authorization: `Token ${token}`,
        },
      });
      setBrandsList(response.data.brands);
      setModelsList(response.data.models);
      setCategoriesList(response.data.categories);
      setSubcategoriesList(response.data.subcategories);
      setUnitsList(response.data.uoms);
      setWarehousesList(response.data.warehouses);
      setVariantsList(response.data.variants);
      setProductTypesList(response.data.product_types);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchEntities();
    calculateVolume();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [VHeight, VWidth, VLength]);

  const fetchCatSubcats = (id) => {
    setProductCategory(id);
    axios
      .get(`${baseUrl}/api/categories/${id}/subcategories`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        setSubcategoriesList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const editProduct = () => {
    if (productImages.length === 0) {
      toast.error("Please upload an image.");
      return;
    }

    // if (priceValidity != "" && typeof priceValidity !== "string") {
    //   var priceValidityDate = dayjs(priceValidity).format("YYYY-MM-DD");
    // }

    const totalWarehouseStock = calculateTotalWarehouseStock();
    const productQuantityValue =
      parseFloat(productQuantity.replace(/,/g, "")) || 0;

    if (totalWarehouseStock > productQuantityValue) {
      toast.error(
        "Total stock quantity cannot be greater than product quantity."
      );
      return;
    }

    const commons = {
      ar: {
        productName: arabicProductName,
        description: arabicDesc,
        serviceTerms: arabicServiceTerms,
      },
      en: {
        productName,
        description,
        serviceTerms,
      },
    };
    const stock = {
      psId,
      productQuantity: pq,
    };
    setNavigating(true);

    const prodFormdata = new FormData();
    prodFormdata.append("psId", psId);
    prodFormdata.append("productStatus", 1);
    prodFormdata.append("commons", JSON.stringify(commons));
    prodFormdata.append("stock", JSON.stringify(stock));
    prodFormdata.append("productBarcode", productBarcode);
    console.log("Check Brand", typeof brand)
    console.log("Check Brand 2", brand)
    if(brand !== null) {
      prodFormdata.append("brand", brand);
    }
    console.log("Check Model", typeof model)
    console.log("Check Model 2", model)

    if(model !== null) {
      prodFormdata.append("model", model);
    }
    prodFormdata.append("productType", productType);
    prodFormdata.append("productCategory", productCategory);
    prodFormdata.append("productSubcategory", productSubcategory);
    prodFormdata.append("minQty", mq);
    prodFormdata.append("orderLimit", ol);
    prodFormdata.append("reorderLevel", rol);
    prodFormdata.append("purchasePrice", pp);
    prodFormdata.append("sellingPrice", sp);
    prodFormdata.append("onlinePrice", op);
    if (!uom) {
      prodFormdata.append("productUnit", "");
    } else {
      prodFormdata.append("productUnit", uom);
    }

    prodFormdata.append("code", code);
    prodFormdata.append("glaccount", glaccount);
    prodFormdata.append("origin", origin);
    prodFormdata.append("video_url", videoURL);
    if (!priceValidity) {
      prodFormdata.append("priceValidity", "");
    } else {
      prodFormdata.append("priceValidity", priceValidity);
    }
    prodFormdata.append("discount_type", discountType);
    prodFormdata.append("discount", discountValue);
    for (let i = 0; i < productImages.length; i++) {
      if ("image" in productImages[i]) {
        prodFormdata.append("productImagesIds[]", productImages[i].piId);
      } else {
        prodFormdata.append("productImages[]", productImages[i]);
      }
    }
    const tagsObject = tags?.map((tag) => {
      return {
        tag,
      };
    });
    prodFormdata.append("tags", JSON.stringify(tagsObject));
    prodFormdata.append("weight", weight);
    prodFormdata.append("height", VHeight);
    prodFormdata.append("width", VWidth);
    prodFormdata.append("length", VLength);
    prodFormdata.append("volume", volume);
    prodFormdata.append("productWarehouses", JSON.stringify(productWarehouses));
    const screenRoute = serviceScreen ? "services" : "products-list";
    axios
      .put(`${baseUrl}/api/product/${prod_id}`, prodFormdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      })
      .then(() => {
        toast.success(
          `${
            screenRoute
              ? "Service updated successfully!"
              : "Product updated successfully!"
          }`
        );
        setTimeout(() => {
          setNavigating(false);
          navigate(`/dashboard/${screenRoute}`);
        }, 2500);
        localStorage.setItem("products", "products");
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.data) {
            const errorMessages = [];
            for (const key in err.response.data) {
              if (Array.isArray(err.response.data[key])) {
                errorMessages.push(...err.response.data[key]);
                toast.error(`${key} ${err.response.data[key][0]}`);
              }
            }
            if (errorMessages.length > 0) {
            } else {
              toast.error("Failed to update product!");
            }
          } else {
            toast.error(
              err.response.data.message || "Failed to update product!"
            );
          }
        } else {
          toast.error("An error occurred. Please try again.");
        }
        setNavigating(false);
      });
  };

  // eslint-disable-next-line no-unused-vars
  const [detail, setDetail] = useState([]);

  const fetchProductDetail = (id) => {
    isLoading(true);
    axios
      .post(
        `${baseUrl}/api/product/edit/id`,
        { prodId: id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((response) => {
        isLoading(false);
        setDetail(response.data);
        setProductName(response.data?.commons?.en?.productName);
        setArabicProductName(response.data?.commons?.ar?.productName);
        setProductBarcode(response.data?.productBarcode);
        setProductQuantity(response.data?.stock?.productQuantity);
        setBrand(response.data?.brand);
        setProductType(response.data?.productType);
        setModel(response.data?.model);
        setProductCategory(response.data?.productCategory);
        setProductSubcategory(response.data?.productSubcategory);
        setUom(response.data?.productUnit);
        setMinQty(response.data?.minQty);
        setOrderLimit(response.data?.orderLimit);
        setPurchasePrice(response.data?.purchasePrice);
        setSellingPrice(response.data?.sellingPrice);
        setOnlinePrice(response.data?.onlinePrice);
        setCode(response.data?.code);
        setOrigin(response.data?.origin);
        setVideoURL(response.data?.video_url);
        setReorderlevel(response.data?.reorderLevel);
        setGlaccount(response.data?.glaccount);
        setDescription(response.data?.commons?.en?.description);
        setArabicDesc(response.data?.commons?.ar?.description);
        setServiceTerms(response.data?.commons?.en?.serviceTerms);
        setArabicServiceTerms(response.data?.commons?.ar?.serviceTerms);
        setProductImages(response.data?.images);
        const parseTags = response.data?.tags?.map((tag) => tag.tag);
        setTags(parseTags === undefined ? [] : parseTags);
        setWeight(response.data?.weight);
        setVHeight(response.data?.height);
        setVWidth(response.data?.width);
        setVLength(response.data?.length);
        setPriceValidity(response.data?.priceValidity);
        setDiscountType(response.data?.discount_type);
        setDiscountValue(response.data?.discount);
        setPsid(response.data?.stock?.psId);
        const discountType = response.data?.discount_type;
        setIsSwitchOn(discountType !== 0);

        // Handling Warehouses
        const tmpWarehouses = response.data?.warehouses;
        const tmpWarehouseList = [];

        for (let i = 0; i < tmpWarehouses.length; i++) {
          tmpWarehouseList.push({
            warehouseId: tmpWarehouses[i].warehouse.uuid, // Replace with the actual key in your warehouse data
            warehouseStock: tmpWarehouses[i].total_stock,
          });
        }

        setProductWarehouses(tmpWarehouseList);
      })
      .catch((err) => {
        console.log("error", err);
        isLoading(false);
      });
  };

  useEffect(() => {
    fetchProductDetail(prod_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const calculateTotalWarehouseStock = () => {
    return productWarehouses.reduce((total, warehouseItem) => {
      const stockValue =
        parseFloat(warehouseItem.warehouseStock.replace(/,/g, "")) || 0;
      return total + stockValue;
    }, 0);
  };

  return (
    <>
      <Wrapper maxWidth={false}>
        <Header data={headerData} />
        {loading ? (
          <Loader />
        ) : (
          <FormWrapper container my={3}>
            <InputField
              label="Product name (Eng) *"
              size={2.8}
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
            />
            <InputField
              label="Product name (Arabic)*"
              size={2.8}
              value={arabicProductName}
              onChange={(e) => setArabicProductName(e.target.value)}
            />
            <InputField
              label="Product Bar Code"
              size={2.8}
              value={productBarcode}
              onChange={(e) => setProductBarcode(e.target.value)}
            />

            <Dropdown
              label="Product Type*"
              size={2.8}
              options={productTypesList}
              value={productType}
              onChange={(e) => setProductType(e.target.value)}
              notAdd={true}
            />

            <Dropdown
              label="Brand"
              size={2.8}
              options={brandsList}
              value={brand}
              onChange={(e) => setBrand(e.target.value)}
              onAddClick={() => handleAddClick("Brand")}
            />
            <Dropdown
              label="Model"
              size={2.8}
              options={modelsList}
              value={model}
              onChange={(e) => setModel(e.target.value)}
              onAddClick={() => handleAddClick("Model")}
            />
            <Dropdown
              label="Category*"
              size={2.8}
              options={categoriesList}
              value={productCategory}
              onChange={(e) => fetchCatSubcats(e.target.value)}
              onAddClick={() => handleAddClick("Category")}
            />
            <Dropdown
              label="Subcategory*"
              size={2.8}
              options={subcategoriesList}
              value={productSubcategory}
              onChange={(e) => setProductSubcategory(e.target.value)}
              onAddClick={() => handleAddClick("Subcategory")}
            />

            <BrandModal
              open={brandModalOpen}
              onClose={handleCloseModals}
              fetchEntities={fetchEntities}
            />
            <ModelModal
              open={modelModalOpen}
              onClose={handleCloseModals}
              fetchEntities={fetchEntities}
            />
            <CategoryModal
              open={categoryModalOpen}
              onClose={handleCloseModals}
              fetchEntities={fetchEntities}
            />
            <SubcategoriesModal
              open={subcategoryModalOpen}
              onClose={handleCloseModals}
              list={categoriesList}
              fetchEntities={fetchEntities}
            />

            <FormattedInput
              label="Product Quantity"
              size={2.8}
              value={productQuantity}
              onChange={setProductQuantity}
            />
            <FormattedInput
              label="Minimum Quantity"
              size={2.8}
              value={minQty}
              onChange={setMinQty}
            />
            <FormattedInput
              label="Order Limit"
              size={2.8}
              value={orderLimit}
              onChange={setOrderLimit}
            />
            <InputField
              label="Re-Order Level"
              size={2.8}
              value={reorderLevel}
              onChange={(e) => setReorderlevel(e.target.value)}
            />

            <FormattedInput
              label="Purchase Price"
              size={2.8}
              value={purchasePrice}
              onChange={setPurchasePrice}
            />
            <FormattedInput
              label="Selling Price"
              size={2.8}
              value={sellingPrice}
              onChange={setSellingPrice}
            />
            <FormattedInput
              label="Online Price*(Excl. Vat Tax)"
              size={2.8}
              value={onlinePrice}
              onChange={setOnlinePrice}
            />
            {/* <InputField label="Batch Number" size={2.8} value={batchNumber} onChange={(e) => setBatchNumber(e.target.value)} /> */}

            <UnitsDropdwon item md={2.8} my={2}>
              <Label>Unit of measure</Label>
              <FormControl
                mt={3}
                size="small"
                sx={{
                  background: "#fff",
                  borderRadius: "12px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "12px",
                }}
              >
                <Select
                  value={uom}
                  onChange={(e) => setUom(e.target.value)}
                  sx={{ width: "100%", borderRadius: "12px" }}
                >
                  {unitsList?.map((unit, index) => {
                    return (
                      <MenuItem key={index} value={unit.id}>
                        {unit.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </UnitsDropdwon>
            <InputField
              label="GTIN / HS Code"
              size={2.8}
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
            <InputField
              label="GL Account"
              size={2.8}
              value={glaccount}
              onChange={(e) => setGlaccount(e.target.value)}
            />
            <InputField
              label="Origin"
              size={2.8}
              value={origin}
              onChange={(e) => setOrigin(e.target.value)}
            />
            <InputField
              label="Video URL"
              size={2.8}
              value={videoURL}
              onChange={(e) => setVideoURL(e.target.value)}
            />

            <Grid item md={6} my={2}>
              <Typography sx={{ fontWeight: "500", fontSize: "12px" }}>
                Price Validity
              </Typography>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                sx={{ paddingTop: "4px", width: "100%" }}
              >
                <DemoContainer
                  components={["DatePicker"]}
                  sx={{ width: "100%" }}
                >
                  <DatePicker
                    format="YYYY-MM-DD"
                    valueFormat="YYYY-MM-DD"
                    value={dayjs(priceValidity)}
                    onChange={(value) => setPriceValidity(value)}
                    slotProps={{
                      textField: { size: "small", error: false },
                    }}
                    sx={{ background: "#fff" }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            <Grid item md={6} my={2}></Grid>

            <Grid item md={5.8}>
              <Typography sx={{ fontWeight: "500", fontSize: "12px" }}>
                Description (English)*
              </Typography>
              <StyledTextarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Grid>
            <Grid item md={5.8}>
              <Typography sx={{ fontWeight: "500", fontSize: "12px" }}>
                Description (Arabic)*
              </Typography>
              <StyledTextarea
                value={arabicDesc}
                onChange={(e) => setArabicDesc(e.target.value)}
              />
            </Grid>
            <Grid item md={5.8}>
              <Typography sx={{ fontWeight: "500", fontSize: "12px" }}>
                Service Terms (English)
              </Typography>
              <StyledTextarea
                value={serviceTerms}
                onChange={(e) => setServiceTerms(e.target.value)}
              />
            </Grid>
            <Grid item md={5.8}>
              <Typography sx={{ fontWeight: "500", fontSize: "12px" }}>
                Service Terms (Arabic)
              </Typography>
              <StyledTextarea
                value={arabicServiceTerms}
                onChange={(e) => setArabicServiceTerms(e.target.value)}
              />
            </Grid>

            <ImgUploader
              onImageUpload={handleImagesChange}
              productImages={productImages}
              setProductImages={setProductImages}
              isEdit={true}
            />

            <Grid item md={2} my={2}>
              <Typography sx={{ fontWeight: "500", fontSize: "12px" }}>
                Tags
              </Typography>
              <TagsInput
                value={tags}
                onChange={handleTagsChange}
                inputProps={{ placeholder: "Press Enter" }}
              />
            </Grid>

            <FormattedInput
              label="Weight (kg)"
              size={1.5}
              value={weight}
              onChange={setWeight}
            />
            <FormattedInput
              label="Height (cm)"
              size={1.5}
              value={VHeight}
              onChange={setVHeight}
            />
            <FormattedInput
              label="Width (cm)"
              size={1.5}
              value={VWidth}
              onChange={setVWidth}
            />
            <FormattedInput
              label="Length (cm)"
              size={1.5}
              value={VLength}
              onChange={setVLength}
            />
            <InputField label="Dimensions (H*W*L)" size={3} value={volume} />

            <Grid item md={12}>
              <Box display={"flex"} alignItems={"center"} gap={3}>
                <Heading>Discount</Heading>
                <Switch
                  size="medium"
                  color="secondary"
                  checked={isSwitchOn}
                  onChange={() => setIsSwitchOn(!isSwitchOn)}
                />
              </Box>
              {isSwitchOn && (
                <Grid container gap={3}>
                  <Grid item md={3} my={2}>
                    <Label>Discount Type</Label>
                    <FormControl
                      size="small"
                      sx={{
                        background: "#fff",
                        borderRadius: "12px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "12px",
                      }}
                    >
                      <Select
                        value={discountType}
                        onChange={(e) => setDiscountType(e.target.value)}
                        sx={{ width: "100%", borderRadius: "12px" }}
                      >
                        <MenuItem value="0">None</MenuItem>
                        <MenuItem value="1">Percentage</MenuItem>
                        <MenuItem value="2">Amount</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <FormattedInput
                    label="Discount Amount"
                    size={3}
                    value={discountValue}
                    onChange={setDiscountValue}
                  />
                </Grid>
              )}
            </Grid>

            <ProductWarehouses
              productWarehouses={productWarehouses}
              warehousesList={warehousesList}
              onUpdateWarehouses={handleWarehouseChanges}
              onAddClick={() => setWarehouseModalOpen(true)}
            />
            <WarehouseModal
              open={warehouseModalOpen}
              onClose={handleCloseModals}
              data={fetchEntities}
            />
          </FormWrapper>
        )}
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            gap: "20px",
          }}
        >
          <Button
            variant="outlined"
            color="secondary"
            sx={{
              height: "40px",
              width: "150px",
              mt: "23px",
              borderRadius: "5px",
            }}
            onClick={() => {
              if (serviceScreen) {
                navigate("/dashboard/services");
              } else {
                navigate("/dashboard/products-list");
              }
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => editProduct()}
            variant="contained"
            color="secondary"
            sx={{
              height: "40px",
              width: "150px",
              mt: "23px",
              borderRadius: "5px",
            }}
          >
            {navigating ? (
              <>
                <MoonLoader color="#fff" size={20} />
              </>
            ) : (
              "Update"
            )}
          </Button>
        </Grid>
      </Wrapper>
    </>
  );
}

// Styled Components

const Wrapper = styled(Container)(() => ({
  padding: "1rem",
  width: "100%",
  height: "calc(100vh - 60px)",
  overflow: "auto",
}));

const FormWrapper = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
}));

const StyledTextarea = styled("textarea")(() => ({
  background: "#fff",
  borderRadius: "8px",
  margin: "8px 0",
  padding: "5px",
  height: "100px",
  width: "100%",
  border: "0px solid ",
}));

const UnitsDropdwon = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "Column",
}));

const Label = styled(Typography)({
  fontSize: "12px",
  fontWeight: "500",
  paddingBottom: "5px",
});

const Heading = styled(Typography)(() => ({
  fontSize: "20px",
  fontWeight: "600",
}));

export default EditProduct;
