import React from 'react'
import { styled } from "@mui/material/styles";

// MUI Components Import
import { Grid, Box, Typography } from '@mui/material'

// Recharts Components Import
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

// Icon Import
import FilterListIcon from "@mui/icons-material/FilterList";

// Dummy Data for Charts
const data = [
  {
    name: 'Jan',
    uv: 4000,
    pv: 2400,
  },
  {
    name: 'Feb',
    uv: 3000,
    pv: 1398,
  },
  {
    name: 'Mar',
    uv: 2000,
    pv: 2749,
  },
  {
    name: 'Apr',
    uv: 2780,
    pv: 3908,
  },
  {
    name: 'May',
    uv: 2000,
    pv: 8800,
  },
  {
    name: 'Jun',
    uv: 2000,
    pv: 5852,
  },
  {
    name: 'Jul',
    uv: 2000,
    pv: 6574,
  },
  {
    name: 'Aug',
    uv: 2000,
    pv: 2741,
  },
  {
    name: 'Sep',
    uv: 2000,
    pv: 7589,
  },
  {
    name: 'Oct',
    uv: 2000,
    pv: 5784,
  },
  {
    name: 'Nov',
    uv: 2000,
    pv: 8500,
  },
  {
    name: 'Dec',
    uv: 2000,
    pv: 7800,
  },
];

function Chart() {
    return (
        <>
            <Grid container my={3}>
                <Card item md={12}>
                    <Box mt={1} mb={2} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                        <Typography fontWeight={"600"} fontSize={"18px"}>Analytics</Typography>
                        <Box alignItems={"center"} display={"flex"} gap={"20px"}>
                            <Box alignItems={"center"} display={"flex"} gap={"10px"}>
                                <Box height={10} width={10} borderRadius={5} backgroundColor={"#812990"} />
                                <Typography fontSize={"10px"} fontWeight={"300"}>
                                    Product 1
                                </Typography>
                            </Box>
                            <Box alignItems={"center"} display={"flex"} gap={"10px"}>
                                <Box height={10} width={10} borderRadius={5} backgroundColor={"#922785"} />
                                <Typography fontSize={"10px"} fontWeight={"300"}>
                                    Product 2
                                </Typography>
                            </Box>
                            <FilterButton>
                                <FilterListIcon fontSize="12px" />
                                <Typography fontSize={"12px"}>Filter</Typography>
                            </FilterButton>
                        </Box>
                    </Box>                 
                    <Box width={"100%"} height={"350px"}>
                        <ResponsiveContainer width="100%" height="100%">
                        <BarChart data={data} >
                            <XAxis dataKey="name" tick={{fontSize: "12px"}} />
                            <YAxis tickFormatter={(value) => `${value}k`} tick={{fontSize: "12px"}} />
                            <Tooltip />
                            <Bar barSize={20} dataKey="pv" fill="#812990" />
                            <Bar barSize={20} dataKey="uv" fill="#922785" />
                        </BarChart>
                        </ResponsiveContainer>
                    </Box>
                </Card>
            </Grid>
        </>
    )
}

// Styled Components
const Card = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
  background: "#fff",
  padding: "15px 25px",
  borderRadius: "14px",
  border: '1px solid #DDDDDD',
}))

const FilterButton = styled(Box)({
    display: "flex",
    alignItems: "center",
    gap: "4px",
    padding: "6px 10px",
    borderRadius: "4px",
    border: "1px solid #ddd",
});

export default Chart