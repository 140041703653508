import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router";
import { baseUrl } from "../../../../constants/api";
import { useGetOrdersQuery } from "../../../../redux/api/ordersApiSlice";
import { useLoaderContext } from "../../../../contexts/LoaderContext";
import { ORDER_PAGINATION_LIMIT } from "../../../../constants/constant";

import axios from "axios";

// MUI Components import
import {
  Box,
  Button,
  Container,
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Tooltip,
} from "@mui/material";

// Components Import
import Header from "../../components/Header";
import Loader from "../../../Loaders/Loader";
import MUITable, {
  StyledTableCell,
  StyledTableRow,
} from "../../../../components/MUITable";

// Icons Import
import DownloadIcon from "@mui/icons-material/FileDownloadOutlined";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";

// React Toastify Imports
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomChip from "../../../../components/CustomChip";

const headerData = {
  heading: "Orders List",
  subheading: "Keep track of all the orders in one place",
};

const statusColor = {
  1: "#BCFFB6",
  2: "#add8e6",
  3: "#FF0000",
  4: "#FFF6A9",
  5: "#67C6E3",
};

function OrdersList() {
  const token = window.localStorage.getItem("user-token");

  const navigate = useNavigate();
  const { handleLoader } = useLoaderContext();
  const [orders, setOrders] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [orderStatus, setOrderStatus] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);

  const { data, isLoading, isSuccess } = useGetOrdersQuery({
    limit: ORDER_PAGINATION_LIMIT,
    offset: page,
  });

  const fetchOrdersList = () => {
    setSearchTerm("");
    setOrderStatus("");
    setPaymentStatus("");
    handleLoader(true);
    axios
      .get(`${baseUrl}/api/vendor/orders`, {
        params: {
          limit: ORDER_PAGINATION_LIMIT,
          offset: page,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        setOrders(response.data?.results);
        setCount(response.data?.total_count);
        handleLoader(false);
      })
      .catch((err) => {
        handleLoader(false);
      });
  };

  const searchOrder = () => {
    handleLoader(true);
    axios
      .post(
        `${baseUrl}/api/vendor/orders`,
        {
          search_term: searchTerm,
        },
        {
          params: {
            limit: ORDER_PAGINATION_LIMIT,
            offset: page,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((response) => {
        setOrders(response.data?.results);
        setCount(response.data?.total_count);
        handleLoader(false);
      })
      .catch(() => {
        handleLoader(false);
      });
  };

  const applyFilters = () => {
    handleLoader(true);
    let payload = {};

    const order_Status = orderStatus;

    if (orderStatus !== "") {
      payload.status = order_Status;
    }

    if (paymentStatus !== "") {
      payload.payment_status = paymentStatus;
    }

    axios
      .post(`${baseUrl}/api/vendor/orders`, payload, {
        params: {
          limit: ORDER_PAGINATION_LIMIT,
          offset: page,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        setOrders(response.data?.results);
        setCount(response.data?.total_count);
        handleLoader(false);
      })
      .catch((err) => {
        handleLoader(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const downloadInvoice = (id) => {
    handleLoader(true);
    axios
      .get(`${baseUrl}/api/invoice/${id}`, {
        responseType: "blob", // Set the response type to blob to handle binary data
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `invoice_${id}.pdf`);
        document.body.appendChild(link);
        link.click();
        toast.success("Invoice PDF Downloaded!");
        handleLoader(false);
      })
      .catch(() => {
        toast.error("Error downloading Invoice!");
        handleLoader(false);
      });
  };

  useEffect(() => {
    if (data) {
      setCount(data?.total_count);
      setOrders(data?.results);
    }
  }, [data, page]);

  const tableHead = [
    "Order ID",
    "Date",
    "Customer Name",
    "Customer Email",
    "Customer Address",
    "Order Status",
    "Payment ID",
    "Payment Status",
    "Payment Method",
    "Amount",
    "Action",
  ];

  return (
    <>
      <Wrapper maxWidth={false}>
        <Header data={headerData} />
        <SearchNav>
          <Box
            sx={{
              width: "30vw",
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Search>
              <SearchIconWrapper>
                <SearchIcon sx={{ color: "#bdbdbd" }} />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search by Order Id, email"
                inputProps={{ "aria-label": "search" }}
                sx={{ width: "90%", left: "30px" }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Search>
            <Button onClick={() => searchOrder()}>Search</Button>
          </Box>
          <Box
            sx={{
              width: "40%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Dropdown size="small" fullWidth>
              <InputLabel id="order-range-label">Order Status</InputLabel>
              <Select
                sx={{ borderRadius: "6px", border: "none" }}
                labelId="order-status-label"
                id="order-status-select"
                value={orderStatus}
                label="Order Status"
                onChange={(e) => setOrderStatus(e.target.value)}
              >
                <MenuItem value={1}>Order Placed</MenuItem>
                <MenuItem value={2}>Order Accepted</MenuItem>
                <MenuItem value={3}>Order Rejected</MenuItem>
                <MenuItem value={4}>Order Dispatched</MenuItem>
                <MenuItem value={5}>Order Delivered</MenuItem>
              </Select>
            </Dropdown>
            <Dropdown size="small" fullWidth>
              <InputLabel id="spending-filter-label">Payment Status</InputLabel>
              <Select
                sx={{ borderRadius: "6px", border: "none" }}
                labelId="spending-filter-label"
                id="spending-filter-select"
                value={paymentStatus}
                label="Spending filter"
                onChange={(e) => setPaymentStatus(e.target.value)}
              >
                <MenuItem value={1}>Pending</MenuItem>
                <MenuItem value={2}>Paid</MenuItem>
                <MenuItem value={3}>Unpaid</MenuItem>
              </Select>
            </Dropdown>
            <Button onClick={() => applyFilters()}>Apply</Button>
            <Button onClick={() => fetchOrdersList()}>Reset</Button>
          </Box>
        </SearchNav>

        <Box>
          {isLoading && <Loader />}
          <Box
            sx={{
              margin: "10px 0",
              boxShadow: "rgba(0, 0, 0, 0.16) 3px 16px 87px 0px",
            }}
          >
            <MUITable tableHead={tableHead} handleChangePage={handleChangePage}>
              {isSuccess && orders?.length > 0 ? (
                orders?.map((row) => {
                  return (
                    <StyledTableRow key={row?.order?.ordId}>
                      <StyledTableCell>{row?.order?.ordId}</StyledTableCell>
                      <StyledTableCell>
                        {new Date(row.order.created_at).toLocaleString()}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row?.order?.profile?.fullname}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row?.order?.profile?.user_email}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row?.order?.address?.address}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Box
                        // sx={{
                        //   width: "100px",
                        //   height: "30px",
                        //   color: "#000",
                        //   backgroundColor: `${
                        //     statusColor[String(row?.order?.status)]
                        //   }`,
                        //   display: "flex",
                        //   alignContent: "center",
                        //   alignItems: "center",
                        //   justifyContent: "center",
                        //   borderRadius: "50px",
                        //   fontSize: "12px",
                        //   fontWeight: "bold",
                        // }}
                        >
                          {<CustomChip label={row?.order?.status_display} />}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell>
                        {row?.order?.transaction?.fort_id}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row?.order?.transaction?.payment_status_display}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row?.order?.transaction?.payment_method_display}
                      </StyledTableCell>

                      <StyledTableCell>
                        {parseFloat(
                          row?.order?.total_price || 0
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}{" "}
                        SR
                      </StyledTableCell>
                      <StyledTableCell>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "2px",
                            padding: "10px 0",
                          }}
                        >
                          <Tooltip title="view details" placement="top">
                            <IconButton
                              color="primary"
                              onClick={() =>
                                navigate(
                                  `/dashboard/sales/order-detail/${row.order.order_id}`
                                )
                              }
                            >
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="download invoice" placement="top">
                            <IconButton
                              color="primary"
                              onClick={() => downloadInvoice(row?.order?.ordId)}
                            >
                              <DownloadIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })
              ) : (
                <StyledTableRow>
                  <StyledTableCell
                    sx={{ height: "100px" }}
                    colSpan={tableHead?.length}
                    align="center"
                  >
                    <Box
                      sx={{
                        fontSize: "18px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 1,
                      }}
                    >
                      {data?.data?.length === 0 ? "No records found" : ""}
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </MUITable>
            <Box
              sx={{
                background: "#fff",
                display: "flex",
                justifyContent: "end",
                margin: "1px 0 0",
                padding: "15px 10px",
              }}
            >
              <Pagination
                count={Math.ceil(count / ORDER_PAGINATION_LIMIT)}
                shape="rounded"
                onChange={handleChangePage}
              />
            </Box>
          </Box>
        </Box>
      </Wrapper>
    </>
  );
}

// Styled Components

const Wrapper = styled(Container)(() => ({
  padding: "1rem",
  width: "100%",
  height: "calc(100vh - 60px)",
  overflow: "auto",
}));

const Dropdown = styled(FormControl)(() => ({
  width: "100%",
  border: "1px solid #EFEFEF",
  marginTop: "5px",
  backgroundColor: "#fff",
  borderRadius: "6px",
}));

const Search = styled("div")(({ theme }) => ({
  height: "40px",
  position: "relative",
  borderRadius: "10px",
  backgroundColor: "#F7F9FB",
  display: "flex",
  alignItems: "center",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchNav = styled(Box)(() => ({
  width: "100%",
  height: "auto",
  padding: "20px",
  backgroundColor: "#fff",
  border: "1px solid #E8E8E8",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  margin: "10px 0",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 1.5),
  height: "100%",
  position: "absolute",
  left: "0",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "35ch",
    },
  },
}));

export default OrdersList;
