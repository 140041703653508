import React, { useEffect, useRef, useState } from 'react'
import { styled } from "@mui/material/styles";
import axios from 'axios';

// MUI Components Import
import { Container, Grid, Box, Typography, Button, List, ListItem, ListItemText, Tooltip } from '@mui/material'

// React Toastify Imports
import { toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Loader Import
import { MoonLoader } from 'react-spinners';

// Components Import
import Header from '../../components/Header';

// Icons Import
import AddPhoto from '@mui/icons-material/AddPhotoAlternate';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { baseUrl } from '../../../../constants/api';

const headerData = {
    heading: "Upload your Banners",
    subheading: "Upload banners for your store"
}

function Banners() {

    const token = window.localStorage.getItem("user-token");

    const imgUpload = useRef();

    const selectFiles = () => {
        imgUpload.current.click();
    };

    const [uploading, setUploading] = useState(false)
    const [banners, setBanners] = useState([])
    const [imageBase64Strings, setImageBase64Strings] = useState([]);

    const handleImageUpload = async (e) => {
        const newImageBase64Strings = [];

        for (let i = 0; i < e.target.files.length; i++) {
            const file = e.target.files[i];

            // Convert the image file to base64
            const base64String = await convertToBase64(file);
            newImageBase64Strings.push(base64String);
        }

        setImageBase64Strings((prevImageBase64Strings) => [...prevImageBase64Strings, ...newImageBase64Strings]);
    };

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result.split(',')[1]); 
            };
            reader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const handleRemoveImage = (indexToRemove) => {
        setImageBase64Strings((prevImageBase64Strings) =>
            prevImageBase64Strings.filter((_, index) => index !== indexToRemove)
        );
    };

    const uploadBanners = () => {
        setUploading(true)
        axios.post(`${baseUrl}/api/banner/create`,
            {
                images_base64: imageBase64Strings,
                heading: ""
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${token}`,
                },
            }
        )
        .then((response) => {
            toast.success("Banners Added successfully!");
            setTimeout(() => {
                setUploading(false)
            }, 2000);
            setImageBase64Strings([])
            fetchBanners()
        })
        .catch((err) => {
            toast.error("Failed to add Banners!");
            setUploading(false)
        })
    }

    const fetchBanners = () => {
        axios.get(`${baseUrl}/api/user/banners`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${token}`,
                },
            }
        )
        .then((response) => {
            setBanners(response.data)
        })
        .catch((err) => {
            console.log("Error", err)
        })
    }

    const deleteBanner = (id) => {
        axios.delete(`${baseUrl}/api/banner/delete/${id}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${token}`,
                },
            }
        )
        .then(() => {
            toast.success("Banner has been deleted!");
            fetchBanners()
        })
        .catch((err) => {
            console.log("Error", err)
        })
    }
    

    useEffect(() => {
        fetchBanners()
    }, [])
    

    return (
        <>
            <Wrapper maxWidth={false}>
                <Header data={headerData} />
                <Grid container my={5}>
                    <Uploader item md={1} onClick={() => selectFiles()}>
                        <input type="file" accept="image/*" multiple ref={imgUpload} style={{ display: 'none' }} onChange={handleImageUpload} id="imageInput"/>
                        <AddPhoto sx={{ fontSize: '25px' }} />
                        <Typography sx={{ fontWeight: '500', fontSize: '12px', color: '#2A3F54' }}>
                            Add Images
                        </Typography>                    
                    </Uploader>
                    <Grid item md={12}>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {imageBase64Strings.map((imageUrl, index) => (
                                <>
                                    <ImgDisplay key={index} sx={{background: `url(data:image/jpeg;base64,${imageUrl}) center/cover no-repeat`}}/>
                                    <CancelIcon sx={{fontSize: "20px", position: "relative", right: "20px", cursor: "pointer" }}
                                        onClick={() => handleRemoveImage(index)}
                                    />
                                </>
                            ))}
                        </div>
                    </Grid>
                    <Grid item md={12}>
                        <List>
                            <Text>
                               * File size must be less than 1MB.
                            </Text>
                        </List>
                        <List>
                            <Text>
                               * Only JPG (JPEG) and PNG file formats are permitted for banner uploads. 
                            </Text>
                        </List>
                        <List>
                            <Text>
                               * Aim for high-quality images with clear resolution(1200*600) and sharp details. Low-quality images may not render correctly or may appear pixelated when displayed on the marketplace.
                            </Text>
                        </List>
                    </Grid>
                </Grid>
                <Box mt={'50px'} gap={'30px'} width={"100%"} display={"flex"} justifyContent={"flex-end"}>
                    {imageBase64Strings.length > 0 && (
                        <Button color="secondary" variant="contained"
                            style={{ textTransform: "none", width: '100px' }}
                            onClick={() => uploadBanners()}
                        >
                            {uploading ? (
                                <>                                    
                                    <MoonLoader color="#fff" size={20} />
                                </>
                                ) : (
                                "Upload"
                            )}
                        </Button>
                    )}
                </Box>
                <Grid container my={5}>
                    {banners.length > 0 ?
                        <>
                            <Grid item md={12}>
                                <Typography sx={{ fontWeight: '600', fontSize: '18px', color: '#2A3F54' }}>
                                    Uploaded Banners
                                </Typography>
                            </Grid>
                            <Grid item md={12}>
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    {banners.map((banner, index) => (
                                        <>
                                            <ImgDisplay key={index} sx={{background: `url(${banner.image}) center/cover no-repeat`}}>
                                                <Tooltip title="delete" placement='top' >
                                                    <DeleteIcon sx={{fontSize: "26px", position: "relative", left: "94%", top: "5px", cursor: "pointer", background: "#fff", color: "red", borderRadius: "5px" }}
                                                        onClick={() => deleteBanner(banner.bannerID)}
                                                    />
                                                </Tooltip>
                                            </ImgDisplay>
                                        </>
                                    ))}
                                </div>
                            </Grid>                        
                        </>
                        :
                        null
                    }
                </Grid>
            </Wrapper>
        </>
    )
}

// Styled Components

const Wrapper = styled(Container)(() => ({
    padding: "1rem",
    width: "100%",
    height: "calc(100vh - 60px)",
    overflow: "auto",
}))

const Text = styled(Typography)(() => ({
    fontSize: "10px",
    color: "grey"
}))

const Uploader = styled(Grid)(() => ({
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'center',
    alignItems: 'center',
    height: "90px",
    cursor: "pointer"
}))

const ImgDisplay = styled(Box)(() => ({
    width: '40%',
    height: '200px',
    backgroundColor: '#fff',
    margin: '10px',
    borderRadius: '10px',
}))

export default Banners