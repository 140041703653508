import { styled } from "@mui/material/styles";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
// MUI Components Import
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
// import icons
import Layers from "@mui/icons-material/Layers";
// import pik
import { baseUrl } from "../../../../constants/api";
import ChartPlaceholder from "../components/ChartPlaceholder";

// Dummy Data for Charts
const dataChart = [
  {
    name: "Jan",
    uv: 0,
  },
  {
    name: "Feb",
    uv: 0,
  },
  {
    name: "Mar",
    uv: 0,
  },
  {
    name: "Apr",
    uv: 0,
  },
  {
    name: "May",
    uv: 0,
  },
  {
    name: "Jun",
    uv: 0,
  },
  {
    name: "Jul",
    uv: 0,
  },
  {
    name: "Aug",
    uv: 0,
  },
  {
    name: "Sep",
    uv: 0,
  },
  {
    name: "Oct",
    uv: 0,
  },
  {
    name: "Nov",
    uv: 0,
  },
  {
    name: "Dec",
    uv: 0,
  },
];
const ProductDetail = () => {
  const token = window.localStorage.getItem("user-token");

  const { prod_id } = useParams();

  const [detail, setDetail] = useState([]);

  const fetchProductDetail = (id) => {
    axios
      .post(
        `${baseUrl}/api/product/id`,
        { prodId: id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((response) => {
        setDetail(response.data[0]);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  useEffect(() => {
    fetchProductDetail(prod_id);
  }, []);

  return (
    <>
      <Wrapper maxWidth={false}>
        <Grid
          container
          my={1}
          gap={"20px"}
          justifyContent={"space-between"}
          display={"flex"}
        >
          <Grid
            item
            md={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: "8px",
            }}
          >
            <Button
              size="small"
              style={{ textTransform: "none" }}
              variant="outlined"
              color="primary"
              backgroundcolor={"green"}
              startIcon={<Layers />}
            >
              Reorder
            </Button>
            <Button
              size="small"
              style={{ textTransform: "none" }}
              variant="outlined"
              color="warning"
              backgroundcolor={"green"}
              startIcon={<Layers />}
            >
              Restock
            </Button>

            <Button
              size="small"
              style={{ textTransform: "none" }}
              variant="outlined"
              marginleft={5}
              color="error"
              startIcon={<Layers />}
            >
              Out of Stock
            </Button>
          </Grid>
          <Grid
            item
            md={12}
            display={"flex"}
            flexDirection={"row"}
            gap={"20px"}
          >
            <Grid
              item
              md={6}
              sx={{
                border: " 1px solid #DDDDDD",
                borderRadius: "8px",
                height: "auto",
                background: "#fff",
              }}
            >
              <ChartPlaceholder />
            </Grid>
            <Grid
              item
              md={6}
              sx={{
                border: " 1px solid #DDDDDD",
                borderRadius: "8px",
                height: "auto",
                background: "#fff",
              }}
            >
              <ChartPlaceholder />
            </Grid>
          </Grid>
          <Grid container my={3}>
            <Card item md={12}>
              <Box
                mt={1}
                mb={2}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Typography fontWeight={"600"} fontSize={"18px"}>
                  Product Revenue{" "}
                </Typography>
              </Box>
              <Box width={"100%"} height={"350px"}>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={dataChart}>
                    <XAxis dataKey="name" tick={{ fontSize: "12px" }} />
                    <YAxis
                      tickFormatter={(value) => `${value}k`}
                      tick={{ fontSize: "12px" }}
                    />
                    <Bar barSize={20} dataKey="uv" fill="#922785" />
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            </Card>
          </Grid>
          <Grid
            item
            md={12}
            sx={{
              border: " 1px solid #DDDDDD",
              borderRadius: "8px",
              height: "auto",
              background: "#fff",
            }}
          >
            <TableWrapper component={Paper}>
              <Table>
                <TableHead style={{ backgroundColor: "#DDDDDD" }}>
                  <TableRow>
                    <TableHeadings>ID</TableHeadings>
                    <TableHeadings>Name</TableHeadings>
                    <TableHeadings>Category</TableHeadings>
                    <TableHeadings>Price</TableHeadings>
                    <TableHeadings>Total Quantity</TableHeadings>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableContent sx={{ width: "auto" }}>
                      {detail?.prodId}
                    </TableContent>
                    <TableContent sx={{ width: "auto" }}>
                      {detail?.productName}
                    </TableContent>
                    <TableContent sx={{ width: "auto" }}>
                      {detail?.productCategory} / {detail?.productSubcategory}{" "}
                    </TableContent>
                    <TableContent
                      sx={{ color: "black", width: "15%", fontSize: "16px" }}
                    >
                      {detail?.sellingPrice} SAR{" "}
                    </TableContent>
                    <TableContent
                      sx={{
                        color: "black",
                        width: "15%",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      {detail?.productQuantity}{" "}
                    </TableContent>
                  </TableRow>
                </TableBody>
              </Table>
            </TableWrapper>
          </Grid>
        </Grid>
      </Wrapper>
    </>
  );
};

// Styled Components

const Wrapper = styled(Container)(() => ({
  padding: "1rem",
  width: "100%",
  height: "calc(100vh - 60px)",
  overflow: "auto",
}));
const TableWrapper = styled(TableContainer)(() => ({
  height: "auto",
  overflow: "auto",
  boxShadow: "none",
}));

const TableHeadings = styled(TableCell)(() => ({
  fontWeight: "600",
  fontSize: "14px",
  lineHeight: "16px",
  color: "black",
  background: "#F2F4F5",
}));

const TableContent = styled(TableCell)(() => ({
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "16px",
  color: "#71747D",
  border: "none",
}));
const FilterButton = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "4px",
  padding: "6px 10px",
  borderRadius: "4px",
  border: "1px solid #ddd",
});
const Card = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
  background: "#fff",
  padding: "15px 25px",
  borderRadius: "14px",
  border: "1px solid #DDDDDD",
}));

export default ProductDetail;
