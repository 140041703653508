import React from "react";
import { styled } from "@mui/material/styles";

// MUI Components Import
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";

function createData(name, assigned, closed) {
  return { name, assigned, closed };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0),
  createData('Ice cream sandwich', 237, 9.0),
  createData('Eclair', 262, 16.0),
  createData('Cupcake', 305, 3.7),
  createData('Gingerbread', 356, 16.0),
];

const Teammate = () => {
  return (
        <Box sx={{background: "#fff"}} border={"1px solid #ddd"} borderRadius={"10px"}>
            <CardHeading>Notes and Comments</CardHeading>
            <TableWrapper component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableHeadings>Member Name</TableHeadings>
                            <TableHeadings>Assigned Conversations</TableHeadings>
                            <TableHeadings>Closed Conversations</TableHeadings>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow key={row.name}>
                                <TableContent>{row.name}</TableContent>
                                <TableContent>{row.assigned}</TableContent>
                                <TableContent>{row.closed}</TableContent>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableWrapper>        
        </Box>
  );
};

// Styled Components

const CardHeading = styled(Typography)(() => ({
    fontSize: "16px",
    fontWeight: "600",
    padding: "12px 24px",
}))

const TableWrapper = styled(TableContainer)(() => ({
	height: "auto",
	overflow: "auto",
	border: "none",
	boxShadow: "none"
}))

const TableHeadings = styled(TableCell)(() => ({
	fontWeight: "600",
	fontSize: "14px",
	lineHeight: "16px",
	color: "#A3AED0",
    background: "#F7F9FB"
}))

const TableContent = styled(TableCell)(() => ({
	fontWeight: "400",
	fontSize: "12px",
	lineHeight: "16px",
	color: "#71747D"
}))

export default Teammate;
