import React, { useEffect, useRef, useState } from "react";
import axios from "axios";

// MUI Components Import
import {
  Box,
  Button,
  Checkbox,
  Grid,
  TextField,
  Typography,
  styled,
} from "@mui/material";

// Loader Import
import { MoonLoader } from "react-spinners";

// React Toastify Imports
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Icons Import
import AddPhotoIcon from "@mui/icons-material/AddPhotoAlternate";
import CancelIcon from "@mui/icons-material/Cancel";
import { baseUrl } from "../../../../../../constants/api";

function About() {
  const token = window.localStorage.getItem("user-token");

  const imgUpload = useRef();

  const selectFiles = () => {
    imgUpload.current.click();
  };

  const [saving, setSaving] = useState(false);
  const [aboutId, setAboutId] = useState("");
  const [image, setImage] = useState(null);
  const [previewImage, setPreviewImg] = useState(null);
  const [crn, setCRN] = useState("");
  const [active, setActive] = useState(false);
  const [engCopyright, setEngCopyright] = useState("");
  const [arbCopyright, setArbCopyright] = useState("");

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      setPreviewImg(URL.createObjectURL(file));
    }
  };

  const removeImage = () => {
    setImage(null);
    setPreviewImg(null);
  };

  const fetchAboutData = () => {
    axios
      .get(`${baseUrl}/api/footer/about`)
      .then((response) => {
        if(response.data.uuid){
          setAboutId(response.data.uuid);
        } else {
          setAboutId("");
        }
        setActive(response.data.is_active);
        setCRN(response.data.cr_number);
        setEngCopyright(response.data.eng_copyright);
        setArbCopyright(response.data.arb_copyright);
        setImage(response.data.logo);
        setPreviewImg(response.data.logo);
      })
      .catch(() => {
        // Handle error
      });
  };

  const handleAbout = () => {
    if (!image) {
      toast.error("Please upload an image.");
      return;
    }
    setSaving(true);

    const formData = new FormData();

    formData.append("is_active", active);
    formData.append("cr_number", crn);
    formData.append("eng_copyright", engCopyright);
    formData.append("arb_copyright", arbCopyright);
    if (typeof image !== "string") {
      formData.append("logo", image);
    }

    let response;

    if (aboutId === "") {
      response = axios.post(`${baseUrl}/api/footer/about/create`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      });
    } else {
      response = axios.put(`${baseUrl}/api/footer/about/${aboutId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      });
    }

    response
      .then(() => {
        toast.success("Footer about section added successfully!");
        setTimeout(() => {
          setSaving(false);
        }, 2000);
        setImage(null);
        fetchAboutData();
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
        }
        setSaving(false);
      });
  };

  useEffect(() => {
    fetchAboutData();
  }, []);

  return (
    <>
      <SectionWrapper mt={3}>
        <Heading>About Section</Heading>
        <Grid
          container
          mt={1}
          spacing={3}
          display={"flex"}
          alignItems={"center"}
        >
          <Grid item md={12} display={"flex"} alignItems={"center"}>
            <Uploader item md={1} onClick={() => selectFiles()}>
              <input
                type="file"
                accept="image/*"
                ref={imgUpload}
                style={{ display: "none" }}
                onChange={(e) => handleImageUpload(e)}
                id="imageInput"
              />
              <AddPhotoIcon sx={{ fontSize: "25px" }} />
              <Typography
                sx={{ fontWeight: "500", fontSize: "12px", color: "#2A3F54" }}
              >
                Add Image
              </Typography>
            </Uploader>
            <Grid item md={9}>
              {image ? (
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <ImgDisplay
                    sx={{
                      background: `url(${previewImage}) center/cover no-repeat`,
                    }}
                  />
                  <CancelIcon
                    sx={{
                      fontSize: "20px",
                      position: "relative",
                      right: "20px",
                      cursor: "pointer",
                    }}
                    onClick={() => removeImage()}
                  />
                </div>
              ) : null}
            </Grid>
          </Grid>
          <Grid item md={5}>
            <Label>CR Number</Label>
            <Input
              mt={3}
              type="text"
              color="success"
              InputProps={{ sx: { borderRadius: 3 } }}
              value={crn}
              onChange={(e) => setCRN(e.target.value)}
            />
          </Grid>
          <Grid item md={2}>
            <Label>Show on Marketplace</Label>
            <Checkbox
              type="checkbox"
              checked={active}
              onChange={(e) => setActive(e.target.checked)}
            />
          </Grid>
          <Grid item md={5.5}>
            <Label>Text (English)</Label>
            <StyledTextarea
              InputProps={{ sx: { borderRadius: 3 } }}
              value={engCopyright}
              onChange={(e) => setEngCopyright(e.target.value)}
            />
          </Grid>
          <Grid item md={5.5}>
            <Label>Text (Arabic)</Label>
            <StyledTextarea
              InputProps={{ sx: { borderRadius: 3 } }}
              value={arbCopyright}
              onChange={(e) => setArbCopyright(e.target.value)}
            />
          </Grid>

          <Grid item md={12} sx={{ display: "flex", justifyContent: "end" }}>
            <SaveBTN onClick={() => handleAbout()}>
              {saving ? (
                <>
                  <MoonLoader color="#fff" size={20} />
                </>
              ) : (
                "Save"
              )}
            </SaveBTN>
          </Grid>
        </Grid>
      </SectionWrapper>
    </>
  );
}

const SectionWrapper = styled(Box)(() => ({
  width: "100%",
  background: "#fff",
  padding: "10px 15px",
  borderRadius: "12px",
  fontSize: "22px",
  fontWeight: "600",
}));

const Heading = styled(Typography)(() => ({
  fontSize: "16px",
  fontWeight: "600",
  color: "#424242",
}));

const Input = styled(TextField)({
  width: "100%",
  background: "#fff",
  borderRadius: "5px",
});

const Label = styled(Typography)(() => ({
  fontSize: "12px",
  fontWeight: "500",
}));

const StyledTextarea = styled("textarea")(() => ({
  background: "#fff",
  borderRadius: "5px",
  padding: "5px",
  height: "55px",
  width: "100%",
}));

const Uploader = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "90px",
  cursor: "pointer",
}));

const ImgDisplay = styled(Box)(() => ({
  width: "100px",
  height: "90px",
  backgroundColor: "#fff",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "10px",
  borderRadius: "10px",
}));

const SaveBTN = styled(Button)(({ theme }) => ({
  width: "120px",
  height: "40px",
  marginTop: "15px",
  fontSize: "16px",
  background: `${theme.palette.primary.main}`,
  color: "#fff",
  borderRadius: "5px",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: `${theme.palette.primary.main}`,
  },
}));

export default About;
