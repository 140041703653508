import React from "react";
import { styled } from "@mui/material/styles";

// MUI Components Import
import { Container } from "@mui/material";

// Components Import
import Navbar from "../components/Navbar";
import Dashboard from "./Dashboard";

function HR() {
  return (
        <Wrapper maxWidth={false}>
            <Navbar />
            <Dashboard />
        </Wrapper>
  );
}

const Wrapper = styled(Container)(() => ({
    padding: "0 !important",
	width: "100%",
	height: "auto",
    overflow: "auto"
}))

export default HR;
