import { styled } from "@mui/material/styles";
import axios from "axios";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";
// MUI Components Import
import { Box, Button, Container } from "@mui/material";
// MUI Icons Import
import UploadIcon from "@mui/icons-material/FileUploadOutlined";
// Components Import
import Header from "../components/Header";
import DashboardCards from "./components/DashboardCards";
import ReportCards from "./components/ReportCards";
// Assets Import
import { useState } from "react";
import iconOne from "../../../assets/dash-card-icon-one.webp";
import iconThree from "../../../assets/dash-card-icon-three.webp";
import iconTwo from "../../../assets/dash-card-icon-two.webp";
import { baseUrl } from "../../../constants/api";

const headerData = {
  heading: "Inventory Management",
  subheading: "",
};

function Dashboard() {
  const token = window.localStorage.getItem("user-token");

  const navigate = useNavigate();

  const [cardData, setCardData] = useState([]);

  const fetchSummaryCounts = async () => {
    await axios
      .get(`${baseUrl}/api/products/counts`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        setCardData([
          {
            backgroundColor: "#812990",
            icon: iconOne,
            heading: "Total Products",
            number: response.data.total_products,
          },
          {
            backgroundColor: "#812990",
            icon: iconTwo,
            heading: "New Products",
            number: response.data.newly_added_products_today,
          },
          {
            backgroundColor: "#812990",
            icon: iconThree,
            heading: "Out of Stock",
            number: response.data.out_of_stock_products,
          },
          // {
          //   backgroundColor: "#812990",
          //   icon: iconFour,
          //   heading: "Purchases in Process",
          //   number: response.data.purchase_in_progress_products,
          // },
        ]);
      })
      .catch((err) => {
        console.error("Error fetching demand data:", err);
      });
  };

  useEffect(() => {
    fetchSummaryCounts();
  }, []);

  return (
    <>
      <Wrapper maxWidth={false}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Header data={headerData} />
          <Button
            variant="contained"
            color="secondary"
            startIcon={<UploadIcon />}
            onClick={() => navigate("bulk")}
          >
            Upload
          </Button>
        </Box>
        <DashboardCards data={cardData} />
        {/* <ChartCards /> */}
        {/* <NotificationCards /> */}
        {/* <ProductCards /> */}
        <ReportCards />
      </Wrapper>
    </>
  );
}

// Styled Components

const Wrapper = styled(Container)(() => ({
  padding: "1rem",
  width: "100%",
  height: "calc(100vh - 60px)",
  overflow: "auto",
}));

export default Dashboard;
