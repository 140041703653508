import React from 'react'
import { styled } from "@mui/material/styles";

// MUI Components Import
import { Grid, Box, Typography} from '@mui/material'

// Recharts Components Import
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';

import FilterListIcon from "@mui/icons-material/FilterList";

// Dummy Data for Charts
const data = [
    {
        name: 'Jackets',
        uv: 1125,
    },
    {
        name: 'Sunglasses',
        uv: 400,
    },
    {
        name: 'Watch',
        uv: 1500,
    },
    {
        name: 'Safety Tools',
        uv: 50,
    },
    {
        name: 'Drill Machine',
        uv: 250,
    },
    {
        name: 'Earbuds',
        uv: 450,
      }
];

function DefectedItems() {
    return (
        <>
            <Card item md={8.2}>
                <Box mt={1} mb={2} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                    <Typography fontWeight={"600"} fontSize={"18px"}>
                        Highest Defected Items
                    </Typography>
                    <Box alignItems={"center"} display={"flex"} gap={"20px"}>
                        <FilterButton>
                            <FilterListIcon fontSize="12px" />
                            <Typography fontSize={"12px"}>Filter</Typography>
                        </FilterButton>
                    </Box>
                </Box>
                <Box sx={{height:"280px"}}>
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart data={data}>
                            <CartesianGrid  />
                            <XAxis dataKey="name" tick={{fontSize: "10px"}} />
                            <YAxis tick={{fontSize: "10px"}} />
                            <Bar barSize={15} dataKey="uv" fill="#8884d8" />
                        </BarChart>
                    </ResponsiveContainer>                      
                </Box>
            </Card>
        </>
    )
}

// Styled Componenets

const Card = styled(Grid)(() => ({
    display: "flex",
    flexDirection: "column",
    background: "#fff",
    padding: "15px 25px",
    borderRadius: "14px",
    border: "1px solid #ddd"
}))

const FilterButton = styled(Box)({
    display: "flex",
    alignItems: "center",
    gap: "4px",
    padding: "6px 10px",
    borderRadius: "4px",
    border: "1px solid #ddd",
  });

export default DefectedItems