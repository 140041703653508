import React, { useState } from "react";

// MUI Components Import
import { Box, Button, Grid } from "@mui/material";

// Components Import
import Header from "../components/Header";
import MyModal from "../components/MyModal";
import ServicesCards from "../components/ServicesCards";

// Assets Import
import Check from "../../../assets/check.webp";
import Urway from "../../../assets/services/payment-service-one.webp"
import Checkout from "../../../assets/services/payment-service-two.webp"
import Paypal from "../../../assets/services/payment-service-three.webp"
import Mada from "../../../assets/services/payment-service-four.webp"

const headerData = {
    heading: "Connect Payement Gateways",
    subheading: "Please choose which type of payment gateway you want to go with.",
} 

const servicesData = [
    {
        title: "Urway",
        image: Urway,
        key: "visa1",
    },
    {
        title: "Checkout",
        image: Checkout,
        key: "visa2",
    },
    {
        title: "PayPal",
        image: Paypal,
        key: "visa3",
    },
    {
        title: "Mada",
        image: Mada,
        key: "visa4",
    },
];

const Dashboard = () => {

    const [payment, setPayment] = useState(null);
    const [modal, setModal] = useState(false);

    return (
        <Box padding={"40px"} height={"calc(100vh - 60px)"} overflow={"auto"}>
            <MyModal open={modal} setOpen={setModal} desc={""} img={Check}
                title={"You have been successfully connected with the service!"}
            />
            <Header data={headerData} />            
            <Grid mt={4} container gap={4} width={"70%"} margin={"20px auto"}>
                {servicesData.map((item) => {
                    return (
                        <ServicesCards data={item} selected={item.key === payment}
                            onClick={() => setPayment(item.key)}
                        />
                    );
                })}
            </Grid>
            <Box width={"90%"} display={"flex"} justifyContent={"flex-end"}>
                <Button color="secondary" variant="contained"
                    onClick={() => setModal(true)}
                >   
                    Connect
                </Button>
            </Box>
        </Box>
    );
};

export default Dashboard;
