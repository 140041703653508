import React from "react";
import { styled } from "@mui/material/styles";

// MUI Components Import
import { Container } from "@mui/material";

// Components Import
import Header from "../../components/Header";
import TaxSelect from "../../Controls/components/TaxSelect";
import PaymentSelect from "../../Controls/components/PaymentSelect";
import StockLevel from "../../Controls/components/StockLevel";
import PaymentGateways from "../../Controls/components/PaymentGateways";
import DiscountOnFirstOrder from "../../Controls/components/DiscountOnFirstOrder";
import MetaPixel from "../../Controls/components/MetaPixel";

const headerData = {
  heading: "Configure your settings",
  subheading: "",
};

const Configurations = () => {
  return (
    <>
      <Wrapper maxWidth={false}>
        <Header data={headerData} />
        <TaxSelect />
        <DiscountOnFirstOrder />
        <MetaPixel />
        <PaymentGateways />
        {/* <PaymentSelect /> 
                <StockLevel />             */}
      </Wrapper>
    </>
  );
};

// Styled Components

const Wrapper = styled(Container)(() => ({
  width: "100%",
  padding: "1rem",
  height: "calc(100vh - 60px)",
  overflow: "auto",
}));

export default Configurations;
