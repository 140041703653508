import React from "react";
import { styled } from "@mui/material/styles";

// MUI Components Import
import { Box, Typography } from "@mui/material";

// Assets Import
import AvatarOne from "../../../../assets/avatars/avatar-one.webp";
import AvatarTwo from "../../../../assets/avatars/avatar-two.webp";
import AvatarThree from "../../../../assets/avatars/avatar-three.webp";
import AvatarFour from "../../../../assets/avatars/avatar-four.webp";
import AvatarFive from "../../../../assets/avatars/avatar-five.webp";
import AvatarSix from "../../../../assets/avatars/avatar-six.webp";


const data = [
    {
        img: AvatarTwo,
        title: "Store Manager",
        location: "West Jeddah Site",
        time: "06 Hours ago",
    },
    {
        img: AvatarThree,
        title: "Human Resource Executive",
        location: "Main Jeddah Office",
        time: "01 Day ago",
    },
    {
        img: AvatarFour,
        title: "Sales Executive",
        location: "Main jeddah Office",
        time: "18 Hours ago",
    },
    {
        img: AvatarFive,
        title: "Associate Finanace Manager",
        location: "Yanbu Site Office",
        time: "04 Hours ago",
    },
    {
        img: AvatarSix,
        title: "Associate Designer",
        location: "Riyadh Site",
        time: "09 Hours ago",
    },
];

const Rank = styled(Box)({
  borderRadius: "24px",
  backgroundColor: "#f4f6f9",
  padding: "6px 10px",
  display: "flex",
  alignItems: "center",
  gap: "12px",
});

const RecentJobs = () => {
  return (
    <Box sx={{background: "#fff"}} border={"1px solid #ddd"} borderRadius={"10px"} padding={"16px 20px"}>
      <Typography fontWeight={"600"} fontSize={"16px"}>
        RecentJobs
      </Typography>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        gap={"0px"}
        my={2}
      >
        <img
          src={AvatarOne}
          style={{ height: "80px", width: "80px", borderRadius: "40px" }}
          alt="avatar"
        />
        <Typography mt={1} fontSize={"14px"} fontWeight={"700"}>
          Hannan Asghar
        </Typography>
        <Typography fontSize={"10px"} color={"#ABABAB"}>
          Director of Recruitment
        </Typography>
      </Box>
      <Box display={"flex"} flexDirection={"column"} gap={"12px"}>
        {data.map((item) => {
          return (
            <Rank>
              <img
                src={item.img}
                style={{
                  height: "26px",
                  width: "26px",
                  borderRadius: "13px",
                }}
                alt="avatar"
              />
              <Box>
                <Typography fontSize={"12px"} fontWeight={"600"}>{item.title}</Typography>
                <Box display={"flex"} alignItems={"center"} gap>
                  <Typography color={"#A4A4A4"} fontSize={"8px"}>{item.location}</Typography>
                  <Typography color={"#A4A4A4"} fontSize={"8px"}>{item.time}</Typography>
                </Box>
              </Box>
            </Rank>
          );
        })}
      </Box>
    </Box>
  );
};

export default RecentJobs;
