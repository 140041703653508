import React from 'react'
import { styled } from "@mui/material/styles";

// MUI Components Import
import { Box, Grid, Typography } from '@mui/material'

const Container = styled(Grid)({
})

const ImageWrapper = styled(Box)({
    height:180,
    width:320,
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    padding:20,
    borderRadius:16,
    backgroundColor:"white",
    boxShadow:".5px .5px 6px .2px #c5c5c5",
    cursor:"pointer"
})

const ServicesCards = ({data,selected,onClick}) => {
    return (
        <Container item>
            <ImageWrapper onClick={onClick} sx={selected && {border:"1.5px solid #812990"}}>
                <img src={data.image} height={"auto"} width={"60%"} alt="service" />
            </ImageWrapper>
            <Typography mt={"8px"} textAlign={"center"}>
                {data.title}
            </Typography>
        </Container>
    )
}

export default ServicesCards