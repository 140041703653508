import React from 'react'
import { styled } from "@mui/material/styles";

// MUI Components Import
import { Grid, Typography} from '@mui/material'
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
} from "@mui/material";

function Replenishment() {
    return (
        <>
            <Card item md={3.6}>
                <CardHeading>Auto-Replenishment</CardHeading>
                <TableWrapper component={Paper} > 
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableHeadings>Vendor</TableHeadings>
                                <TableHeadings>Department</TableHeadings>
                                <TableHeadings>Amount</TableHeadings>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableContent>Asrar & Co</TableContent>
                                <TableContent>Electricity</TableContent>
                                <TableContent>$580</TableContent>
                            </TableRow>
                            <TableRow>
                                <TableContent>Radiants</TableContent>
                                <TableContent>Construction</TableContent>
                                <TableContent>$1250</TableContent>
                            </TableRow>
                            <TableRow>
                                <TableContent>ICC</TableContent>
                                <TableContent>Hardware</TableContent>
                                <TableContent>$1850</TableContent>
                            </TableRow>
                            <TableRow>
                                <TableContent>Works LLC</TableContent>
                                <TableContent>Construction</TableContent>
                                <TableContent>$2000</TableContent>
                            </TableRow>
                            <TableRow>
                                <TableContent>Hammad & Co</TableContent>
                                <TableContent>Electricity</TableContent>
                                <TableContent>$1224</TableContent>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableWrapper>
            </Card>
        </>
    )
}

// Styled Componenets

const Card = styled(Grid)(() => ({
    display: "flex",
    flexDirection: "column",
    background: "#fff",
    borderRadius: "14px",
    border: "1px solid #ddd"
}))

const CardHeading = styled(Typography)(() => ({
    fontSize: "16px",
    fontWeight: "600",
    padding: "12px 24px"
}))

const TableWrapper = styled(TableContainer)(() => ({
	height: "auto",
	overflow: "auto",
	border: "none",
	boxShadow: "none"
}))

const TableHeadings = styled(TableCell)(() => ({
	fontWeight: "600",
	fontSize: "14px",
	lineHeight: "16px",
	color: "#A3AED0",
    background: "#F7F9FB"
}))

const TableContent = styled(TableCell)(() => ({
	fontWeight: "400",
	fontSize: "12px",
	lineHeight: "16px",
	color: "#71747D"
}))

export default Replenishment