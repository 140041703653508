import { Box, Typography } from "@mui/material";
import React from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import FilterListIcon from "@mui/icons-material/FilterList";
import { styled } from "@mui/material/styles";

const data = [
  {
    name: "09:20 PM",
    Today: 10,
    Yesterday: 20,
      conv: 10,
  },
  {
    name: "10:20 PM",
    Today: 100,
    Yesterday: 10,
      conv: 2210,
  },
  {
    name: "11:20 PM",
    Today: 80,
    Yesterday: 50,
    conv: 800,
  },
  {
    name: "12:20 PM",
    Today: 60,
    Yesterday: 60,
      conv: 2000,
  },
];

const FilterButton = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "4px",
  padding: "6px 10px",
  borderRadius: "4px",
  border: "1px solid #ddd",
});

const SalesLead = () => {
  return (
    <Box sx={{background: "#fff"}} border={"1px solid #ddd"} borderRadius={"10px"} padding={"16px 20px"}>
      <Box
        mt={1}
        mb={2}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography fontWeight={"600"} fontSize={"18px"}>
          Sales Leads
        </Typography>
        <Box alignItems={"center"} display={"flex"} gap={"20px"}>
          <Box alignItems={"center"} display={"flex"} gap={"10px"}>
            <Box height={10} width={10} borderRadius={5} backgroundColor={"#812990"} />
            <Typography fontSize={"10px"} fontWeight={"300"}>
              Yesterday
            </Typography>
          </Box>
          <Box alignItems={"center"} display={"flex"} gap={"10px"}>
            <Box height={10} width={10} borderRadius={5} backgroundColor={"#922785"} />
            <Typography fontSize={"10px"} fontWeight={"300"}>
              Today
            </Typography>
          </Box>
          <FilterButton>
            <FilterListIcon fontSize="12px" />
            <Typography fontSize={"12px"}>Filter</Typography>
          </FilterButton>
        </Box>
      </Box>
      <Box width={"100%"} height={"196px"}>
        <ResponsiveContainer width={"100%"} height={"100%"}>
          <LineChart
            width={730}
            height={250}
            data={data}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" tick={{fontSize: "10px"}} />
            <YAxis dataKey={"conv"} tick={{fontSize: "10px"}} />
            <Tooltip />
            <Line type="linear" dataKey="Today" stroke="#922785" />
            <Line type="linear" dataKey="conv" stroke="#812990" />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export default SalesLead;
