import React from 'react'
import { styled } from "@mui/material/styles";

// MUI Components Import
import { Container, Grid } from "@mui/material";

// Components Import
import DashboardCards from '../InventoryDash/components/DashboardCards';
import Header from '../components/Header';
import SalesLead from "./components/SalesLead";
import Teammate from "./components/Teammate";
import Leaderboard from "./components/Leaderboard";
import Notes from "./components/Notes";

// Assets Import
import iconOne from '../../../assets/crm-icon-one.webp'
import iconTwo from '../../../assets/crm-icon-two.webp'
import iconThree from '../../../assets/crm-icon-three.webp'
import iconFour from '../../../assets/crm-icon-four.webp'

const headerData = {
    heading: "Customer Relationship Management",
    subheading: "Streamline customer interactions, empower team productivity, and drive growth with our intuitive CRM dashboard",
}

const cardData = [
    {
        backgroundColor: "#FFF2E5",
        icon: iconOne,
        heading: "Assigned Tasks",
        number: "840",
    },
    {
        backgroundColor: "#FFDFF6",
        icon: iconTwo,
        heading: "Unassigned Tasks",
        number: "592",
    },
    {
        backgroundColor: "#DDFFE7",
        icon: iconThree,
        heading: "Closed Tasks",
        number: "300",
    },
    {
        backgroundColor: "#FFEBEA",
        icon: iconFour,
        heading: "Channels",
        number: "04",
    },
];

function Dashboard() {
    return (
        <>
            <Wrapper maxWidth={false}>
                <Header data={headerData} />
                <DashboardCards data={cardData} />
                <Grid container mt={2} gap={2} justifyContent={"space-between"}>
                    {/* Sales + Teammate */}
                    <Card item xs={8.2} gap={3}>
                        <SalesLead/>
                        <Teammate/>
                    </Card>
                    {/* Leaderboar + Notes/Comments */}
                    <Card item xs={3.6} gap={3}>
                        <Leaderboard/>
                        <Notes/>
                    </Card>
                </Grid>
            </Wrapper>
        </>
    )
}

// Styled Components
const Wrapper = styled(Container)(() => ({
    padding: "1rem",
	width: "100%",
	height: "calc(100vh - 60px)",
    overflow: "auto"
}))

const Card = styled(Grid)(() => ({
    display: "flex",
    flexDirection: "column",
    borderRadius: "14px"
}))
  

export default Dashboard