import React from 'react'
import { styled } from "@mui/material/styles";

// MUI Components Import

import { InputBase, Grid, Box, Typography } from '@mui/material'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";

// Icons Import
import SearchIcon from "@mui/icons-material/Search";

function Transaction() {
  
    const transactions = [
        {
            product: "Steel Wires",
            total_QTY: "Warehouse A",
            UOM: "kg",
            price: "$667",
            date: "25.04.2023",
            status:"Delivered"
        },
        {
            product: "Copper Cables",
            total_QTY: "Warehouse B",
            UOM: "kg",
            price: "$800",
            date: "14.05.2023",
            status:"Aprroved"
        },
        {
            product: "Alluminium Sheets",
            total_QTY: "Warehouse C",
            UOM: "kg",
            price: "$750",
            date: "15.06.2023",
            status:"Declined"
        },
        {
            product: " Brass Pipes",
            total_QTY: "Warehouse D",
            UOM: "kg",
            price: "$667",
            date: "18.06.2023",
            status:"Delivered"
        },
        {
            product: "Stainless Steel Tubes",
            total_QTY: "Warehouse A",
            UOM: "kg",
            price: "$1850",
            date: "04.08.2023",
            status:"Aprroved"
        },
        {
            product: "Nickel Alloys",
            total_QTY: "Warehouse B",
            UOM: "kg",
            price: "$2500",
            date: "04.09.2023",
            status:"Pending"
        },
    ];
  
    return (
        <>
            <Grid container my={3} >
                <Card item md={12}>
                    <Grid sx={{ display: "flex", flexDirection: 'row', justifyContent: "space-between"}} >
                        <CardHeading fontWeight={"600"} fontSize={"18px"}>Transaction History</CardHeading>
                        <Box py={1} sx={{ width: "auto", display: "flex", justifyContent:'flex-end'}}>
                            <Search>
                                <SearchIconWrapper>
                                    <SearchIcon sx={{ color: "#bdbdbd"}} />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder="Search History..."
                                    inputProps={{ "aria-label": "search" }}
                                    sx={{ width: "90%", left:"30px" }}
                                />                                    
                            </Search>   
                        </Box>
                    </Grid>
                    <TableWrapper component={Paper}>
                        <Table>
                            <TableHead style={{backgroundColor:'#DDDDDD'}}>
                                <TableRow>                                       
                                    <TableHeadings>Product</TableHeadings>
                                    <TableHeadings>Product Stock total Qty</TableHeadings>
                                    <TableHeadings>UOM</TableHeadings>
                                    <TableHeadings>Price</TableHeadings>
                                    <TableHeadings>Date & Time</TableHeadings>
                                    <TableHeadings>Status</TableHeadings>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {transactions.map((content, index) => (
                                    <TableRow key={index}>
                                        <TableContent>{content.product}</TableContent>
                                        <TableContent>{content.total_QTY}</TableContent>
                                        <TableContent>{content.UOM}</TableContent>
                                        <TableContent sx={{ color: "#92278F", fontWeight: "bold", fontSize:'16px'}}>
                                            {content.price}
                                        </TableContent>
                                        <TableContent>{content.date}</TableContent>
                                        <TableContent>{content.status}</TableContent>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableWrapper>
                </Card>
            </Grid>
        </>
    )
}

// Styled Components

const Card = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
  background: "#fff",
  borderRadius: "14px",
  border: '1px solid #DDDDDD',
}))

const CardHeading = styled(Typography)(() => ({
    fontSize: "16px",
    fontWeight: "600",
    padding: "12px 24px",
}))

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: "10px",
    backgroundColor: "#F7F9FB",
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "auto",
    },
}))

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 1.5),
    height: "100%",
    position: "absolute",
	left: "0",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1),
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "35ch",
        },
    },
}))

const TableWrapper = styled(TableContainer)(() => ({
	height: "auto",
	overflow: "auto",
	border: "none",
	boxShadow: "none"
}))

const TableHeadings = styled(TableCell)(() => ({
	fontWeight: "600",
	fontSize: "14px",
	lineHeight: "16px",
	color: "#A3AED0",
    background: "#F7F9FB"
}))

const TableContent = styled(TableCell)(() => ({
	fontWeight: "400",
	fontSize: "12px",
	lineHeight: "16px",
	color: "#71747D"
}))


export default Transaction