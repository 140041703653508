import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import axios from "axios";

// MUI Components import
import {
  Container,
  Button,
  Box,
  Stack,
  TextField,
  IconButton,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

// Components Import
import Header from "../../components/Header";

// React Toastify Imports
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import MUITable, {
  StyledTableRow,
  StyledTableCell,
} from "../../../../components/MUITable";

import { baseUrl } from "../../../../constants/api";
import ReviewEditModal from "../components/Modals/ReviewEditModal";
import { MoonLoader } from "react-spinners";
import CustomChip from "../../../../components/CustomChip";

const headerData = {
  heading: "Reviews List",
  subheading: "",
};

function Reviews() {
  const token = window.localStorage.getItem("user-token");
  const [reviews, setReviews] = useState([]);
  const [selectedReview, setSelectedReview] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchReviews = () => {
    setLoading(true);
    axios
      .get(`${baseUrl}/api/ratings`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        setReviews(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getStatus = (uuid, isApproved) => {
    axios
      .put(
        `${baseUrl}/api/rating/${uuid}`,
        {
          is_approved: isApproved,
          // comment: updateComment,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      )
      .then(() => {
        toast.success("Review has been approved!");
        fetchReviews();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [isEditing, setIsEditing] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleEditClick = () => {
    setIsEditing(true);

    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsEditing(false);
    setIsModalOpen(false);
  };

  useEffect(() => {
    fetchReviews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const statusColor = {
    true: "#BCFFB6",
    false: "#FF0000",
  };

  const tableHead = [
    "#",
    "Product",
    "Ratings",
    "Comments",
    "Customer",
    "Status",
    "Posted At",
    "Action",
  ];

  return (
    <>
      <ReviewEditModal
        open={isModalOpen}
        setOpen={handleModalClose}
        reviewComment={selectedReview?.comment}
        fetchReviews={fetchReviews}
        uuid={selectedReview?.uuid}
        approve={selectedReview?.is_approved}
        setSelectedReview={setSelectedReview}
      />
      <Wrapper maxWidth={false}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Header data={headerData} />
        </Box>

        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100px",
            }}
          >
            <MoonLoader color="#000" size={20} />
            <Typography sx={{ marginLeft: "10px" }}>Please wait...</Typography>
          </Box>
        ) : (
          <MUITable tableHead={tableHead}>
            {reviews?.length > 0 ? (
              reviews?.map((review, index) => {
                return (
                  <StyledTableRow key={review?.ordId}>
                    <StyledTableCell>{index + 1}</StyledTableCell>
                    <StyledTableCell>
                      {review?.rated_product?.commons?.en.productName}
                    </StyledTableCell>
                    <StyledTableCell>{review?.rating}</StyledTableCell>
                    <StyledTableCell>
                      {review?.comment}
                      <IconButton size="small">
                        <EditIcon
                          sx={{ fontSize: "22px" }}
                          onClick={() => {
                            setSelectedReview(review);
                            handleEditClick();
                          }}
                        />
                      </IconButton>
                    </StyledTableCell>
                    <StyledTableCell>
                      {review?.rated_user?.fullname}
                    </StyledTableCell>
                    <StyledTableCell>
                      <Box>
                        {
                          <CustomChip
                            label={
                              review?.is_approved ? "Accepted" : "Rejected"
                            }
                          />
                        }
                      </Box>
                    </StyledTableCell>

                    <StyledTableCell>
                      {new Date(review?.created_at).toLocaleString()}
                    </StyledTableCell>

                    <StyledTableCell>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "2px",
                          padding: "10px 0",
                        }}
                      >
                        {review?.is_approved === true ? (
                          <Button
                            variant="contained"
                            color="error"
                            sx={{
                              height: "40px",
                              width: "90px",
                            }}
                            onClick={() => getStatus(review?.uuid, false)}
                          >
                            Reject
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            sx={{
                              height: "40px",
                              width: "90px",
                            }}
                            onClick={() => getStatus(review?.uuid, true)}
                          >
                            Approve
                          </Button>
                        )}
                      </Box>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })
            ) : (
              <StyledTableRow>
                <StyledTableCell
                  sx={{ height: "100px" }}
                  colSpan={tableHead?.length}
                  align="center"
                >
                  <Box
                    sx={{
                      fontSize: "18px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 1,
                    }}
                  >
                    "No records found"
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            )}
          </MUITable>
        )}
      </Wrapper>
    </>
  );
}

// Styled Components

const Wrapper = styled(Container)(() => ({
  padding: "1rem",
  width: "100%",
  height: "calc(100vh - 60px)",
  overflow: "auto",
}));

export default Reviews;
