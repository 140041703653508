import React, { useEffect, useState } from "react";
import axios from "axios";
import { useUserThemeContext } from "./contexts/ThemeContext";
import Loader from "./pages/Loaders/Loader";
import { baseUrl } from "./constants/api";

const UserTheme = ({ children }) => {

    const { updateNavTheme, updateBgTheme, updateTextTheme } = useUserThemeContext()

    const token = window.localStorage.getItem("user-token");

    const [isLoading, setIsLoading] = useState(true);

    const fetchUserSettings = async () => {
        await axios.get(`${baseUrl}/api/auth/settings`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${token}`,
                },
            }
        ).then((response) => {
            updateNavTheme(response.data.navColor)
            updateBgTheme(response.data.bgColor)
            updateTextTheme(response.data.textColor) 
            setIsLoading(false);           
        }) 
        .catch((err) => {            
            console.log(err)
        })
    };

    useEffect(() => {
        fetchUserSettings();
    }, []);

    return isLoading ? (
        <Loader />
      ) : (
        children
    );

};

export default UserTheme; 