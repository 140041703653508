import React, { useEffect, useState } from 'react';
import { Modal, Box, TextField, Button, Typography, styled } from '@mui/material';
import axios from 'axios';
import { baseUrl } from '../../../../../constants/api';
import { toast } from 'react-toastify';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '8px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
};

function ReviewEditModal({ open, setOpen, reviewComment, fetchReviews, uuid, approve, setSelectedReview }) {
    const token = window.localStorage.getItem("user-token");
    const [loading, setLoading] = useState(false);
    const [comment, setComment] = useState(reviewComment);
    useEffect(() => {
        if (open) {
            setComment(reviewComment);
        }
    }, [reviewComment, open]);

    const updateReview = (uuid, isApproved, setOpen) => {
        setLoading(true);
        axios
            .put(
                `${baseUrl}/api/rating/${uuid}`,
                {
                    comment: comment,
                    is_approved: isApproved,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Token ${token}`,
                    },
                }
            )
            .then(() => {
                toast.success("Review has been approved!");
                setOpen(false);
                setLoading(false);
                fetchReviews();
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            }).finally(() => {
                setSelectedReview(null);
            });
    };

    const handleUpdate = (setOpen) => {
        updateReview(uuid, approve, setOpen);
    };

    const handleChange = (e) => {
        setComment(e.target.value);
    };

    return (
        <Modal open={open} onClose={() => setOpen(false)} >
            <Box sx={style}>
                <Heading>Edit Review</Heading>
                <Input
                    value={comment}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    fullWidth
                />

                <Box
                    sx={{
                        display: "flex",
                        flexDirection: 'row',
                        alignItems: "center",
                        gap: "2px",
                        padding: "10px 0",

                    }}
                >

                </Box>
                <Box display={"flex"} justifyContent={"end"} gap={"15px"}>
                    <BottomButton variant="outlined" color="secondary"

                        {
                            ...({
                                onClick: () => {
                                    if (loading) return;
                                    setOpen(false)
                                    setSelectedReview(null)
                                }
                            })
                        }
                    >
                        Cancel
                    </BottomButton>
                    <BottomButton
                        onClick={() => handleUpdate(setOpen)}
                        variant="contained" color="secondary"
                    >
                        {
                            loading ? 'Updating...' : 'Update'
                        }
                    </BottomButton>
                </Box>
            </Box>
        </Modal >
    );
}

const BottomButton = styled(Button)(({ theme }) => ({
    height: '40px',
    width: '100px',
    mt: '23px',
    borderRadius: '20px',
    [theme.breakpoints.down('sm')]: {
        width: '45%',
    }
}))

const Heading = styled(Typography)(() => ({
    fontSize: '18px',
    fontWeight: '600',
}));

const Input = styled(TextField)(() => ({
    marginTop: '5px',
    background: '#fff',
    borderRadius: '5px',
}));

const SaveBTN = styled(Button)(() => ({
    width: '130px',
    height: '40px',
    margin: '0 auto',
    background: '#168F48',
    color: '#fff',
    borderRadius: '5px',
    '&:hover': {
        backgroundColor: '#168F48',
    },
}));

const CancelBTN = styled(Button)(() => ({
    width: '130px',
    height: '40px',
    background: 'transparent',
    color: '#168F48',
    border: '1px solid #168F48',
    borderRadius: '5px',
    '&:hover': {
        backgroundColor: '#168F48',
        color: '#fff'
    },
}));

export default ReviewEditModal