import React from 'react'
import { styled } from "@mui/material/styles";

// MUI Components Import
import { Container } from '@mui/material'

// Components Import
import Chart from './components/Chart';
import DashboardCards from '../InventoryDash/components/DashboardCards';
import Transaction from './components/Transaction';
import BudgetGraph from './components/BudgetGraph';

// Assets Import
import iconOne from '../../../assets/fm-icon-one.webp'
import iconTwo from '../../../assets/fm-icon-two.webp'
import iconThree from '../../../assets/fm-icon-three.webp'
import iconFour from '../../../assets/fm-icon-four.webp'
import Header from '../components/Header';

const headerData = {
    heading: "Financial Management",
    subheading: "Empowering your financial success, one dashboard at a time",
}

const cardData = [
    {
        backgroundColor: "#FFF2E5",
        icon: iconOne,
        heading: "Total Budget",
        number: "$6320",
    },
    {
        backgroundColor: "#FFDFF6",
        icon: iconTwo,
        heading: "Highest Used Budget",
        number: "$5920",
    },
    {
        backgroundColor: "#DDFFE7",
        icon: iconThree,
        heading: "Total No. of POs",
        number: "840",
    },
    {
        backgroundColor: "#FFEBEA",
        icon: iconFour,
        heading: "Highest Expense",
        number: "$9380",
    },
];

function Dashboard() {
    return (
        <>
            <Wrapper maxWidth={false}>
                <Header data={headerData} />
                <DashboardCards data={cardData} />
                <Chart />
                <Transaction />
                <BudgetGraph />                
            </Wrapper>
        </>
    )
}

// Styled Components

const Wrapper = styled(Container)(() => ({
    padding: "1rem",
	width: "100%",
	height: "calc(100vh - 60px)",
    overflow: "auto"
}))

export default Dashboard