import { Box, Container, Grid, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react';
import Header from '../../components/Header';
import { useLocation } from 'react-router-dom';

const headerData = {
    heading: "XLSX File Upload Log Details",
    subheading: "",
};

function LogDetail() {
    const location = useLocation();
    const { data } = location.state || {};

    return (
        <>
            <Wrapper maxWidth={false}>
                <Header data={headerData} />
                <SectionWrapper mt={3}>
                    <Grid
                        item
                        md={12}
                        sx={{
                            border: "1px solid #DDDDDD",
                            borderRadius: "8px",
                            height: "auto",
                            background: "#fff",
                        }}
                    >
                        <TableWrapper component={Paper}>
                            <Table>
                                <TableHead style={{ backgroundColor: "#DDDDDD" }}>
                                    <TableRow>
                                        <TableHeadings>Product ID</TableHeadings>
                                        <TableHeadings>Product Name</TableHeadings>
                                        <TableHeadings>Upload Status</TableHeadings>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map((item, index) => {
                                        const errors = item.error ? item.error.split(' \n') : [];
                                        return (
                                            <TableRow key={index}>
                                                <TableContent sx={{ width: "auto" }}>
                                                    {item.product_id}
                                                </TableContent>
                                                <TableContent sx={{ width: "auto" }}>
                                                    {item.product_name}
                                                </TableContent>
                                                <TableContent>
                                                    {errors.length > 0 ? (
                                                        <ul>
                                                            {errors.map((error, errorIndex) => (
                                                                <li key={errorIndex}>{error}</li>
                                                            ))}
                                                        </ul>
                                                    ) : (
                                                        <p>Product saved successfully</p>
                                                    )}
                                                </TableContent>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableWrapper>
                    </Grid>
                </SectionWrapper>
            </Wrapper>
        </>
    );
}

const Wrapper = styled(Container)(() => ({
    width: "100%",
    padding: "1rem",
    height: "calc(100vh - 60px)",
    overflow: "auto",
}));

const SectionWrapper = styled(Box)(() => ({
    width: "100%",
    background: "#fff",
    padding: "10px 15px",
    borderRadius: "12px",
    fontSize: "22px",
    fontWeight: "600",
}));

const TableWrapper = styled(TableContainer)(() => ({
    height: "auto",
    overflow: "auto",
    boxShadow: "none",
}));

const TableHeadings = styled(TableCell)(() => ({
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "16px",
    color: "black",
    background: "#F2F4F5",
}));

const TableContent = styled(TableCell)(() => ({
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "16px",
    color: "#71747D",
    border: "none",
}));

export default LogDetail;
