// React Imports
import React, { useState, useEffect } from "react";
// React Toastify
import { toast } from "react-toastify";
// React Spinners
import { MoonLoader } from "react-spinners";
// MUI
import { styled } from "@mui/material/styles";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Tooltip,
} from "@mui/material";
// Components Import
import Header from "../../components/Header";
// Icons Import
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/BorderColor";
// Formik
import { useFormik } from "formik";
// Yup
import * as Yup from "yup";
// Redux
import {
  useCreateVariantMutation,
  useDeleteVariantMutation,
  useGetVariantsQuery,
  useUpdateVariantMutation,
} from "../../../../redux/api/variantsApiSlice";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";

const validationSchema = Yup.object({
  engName: Yup.string().required("English Name is required"),
  arabicName: Yup.string().required("Arabic Name is required"),
});

const headerData = {
  heading: "Configure Variants",
  subheading: "",
};

function Variants() {
  const [variantsList, setVariantsList] = useState([]);
  const [editingVariant, setEditingVariant] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [uuid, setUUID] = useState("");


  const formik = useFormik({
    enableReinitialze: true,
    initialValues: {
      engName: editingVariant != null ? editingVariant.name : "",
      arabicName: editingVariant != null ? editingVariant.arb_name : "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (editingVariant) {
        handleEdit(editingVariant.uuid, values);
      } else {
        handleCreate(values);
      }
    },
  });

  const editVariant = (variant) => {
    formik.setFieldValue("engName", variant.name);
    formik.setFieldValue("arabicName", variant.arb_name);
    setEditingVariant(variant);
  };

  const { data: getVariants, isLoading: getVariantsLoading } =
    useGetVariantsQuery({});

  useEffect(() => {
    if (getVariants) {
      setVariantsList(getVariants);
    }
  }, [getVariants]);

  // CREATE VARIANT
  const [createVariant, { isLoading }] = useCreateVariantMutation();

  const handleCreate = async (formValues) => {
    const payload = {
      name: formValues.engName,
      arb_name: formValues.arabicName,
    };

    try {
      const create = await createVariant(payload);

      if (create) {
        toast.success("Variant created successfully!");
        formik.resetForm();
      }
      //   if (create?.error) {
      //     toast.error("User not found", "error");
      //   }
    } catch (error) {
      console.error("Create Variant Error:", error);
      toast.error(error.response.data.message);
    }
  };

  // UPDATE VARIANT
  const [updateVariant, { isLoading: updateVariantLoading }] =
    useUpdateVariantMutation();

  const handleEdit = async (id, formValues) => {
    const payload = {
      name: formValues.engName,
      arb_name: formValues.arabicName,
    };

    try {
      const update = await updateVariant({ body: payload, id });

      if (update) {
        toast.success("Variant updated successfully!");
        formik.resetForm();
      }
      //   if (update?.error) {
      //     toast.error("User not found", "error");
      //   }
    } catch (error) {
      console.error("Update Variant Error:", error);
      toast.error(error.response.data.message);
    }
  };

  // DELETE VARIANT
  const [
    deleteVariant,
    // { isLoading: deleteVariantLoading }
  ] = useDeleteVariantMutation();

  const handleDelete = async (id) => {
    try {
      const deleteVaria = await deleteVariant(id);

      if (deleteVaria) {
        toast.success("Variant deleted successfully!");
      }
      //   if (deleteVaria?.error) {
      //     toast.error("User not found", "error");
      //   }
    } catch (error) {
      console.error("Delete Variant Error:", error);
      toast.error(error.response.data.message);
    }
  };


  const openConfirmationModal = (id) => {
    setUUID(id);
    setConfirmationModal(true);
  };

  return (
    <>
      {uuid ? (
        <ConfirmationModal
          prodId={uuid}
          open={confirmationModal}
          setOpen={setConfirmationModal}
          heading={"Confirm Variant Deletion"}
          text={"Are you sure you want to delete the Variant?"}
          handleDeleteProduct={handleDelete}
        />
      ) : null}
      <Wrapper maxWidth={false}>
        <Header data={headerData} />
        <ControlWrapper mt={3}>
          <Heading>Create a Variant</Heading>
          <Grid
            container
            mt={1}
            spacing={3}
            display={"flex"}
            alignItems={"center"}
          >
            <Grid item md={4}>
              <Label>Variant Name (English)</Label>
              <Input
                name="engName"
                {...formik.getFieldProps("engName")}
                error={formik.touched.engName && Boolean(formik.errors.engName)}
                helperText={formik.touched.engName && formik.errors.engName}
              />
            </Grid>
            <Grid item md={4}>
              <Label>Variant Name (Arabic)</Label>
              <Input
                name="arabicName"
                {...formik.getFieldProps("arabicName")}
                error={
                  formik.touched.arabicName && Boolean(formik.errors.arabicName)
                }
                helperText={formik.touched.arabicName && formik.errors.arabicName}
              />
            </Grid>
            <Grid item md={4} display={"flex"} justifyContent={"end"}>
              <SaveBTN onClick={() => formik.handleSubmit()}>
                {isLoading || updateVariantLoading ? (
                  <>
                    <MoonLoader color="#fff" size={20} />
                  </>
                ) : editingVariant ? (
                  "Update"
                ) : (
                  "Create"
                )}
              </SaveBTN>
            </Grid>
          </Grid>
        </ControlWrapper>
        <TableWrapper component={Paper}>
          <Table>
            <TableHead style={{ backgroundColor: "#DDDDDD" }}>
              <TableRow>
                <TableHeadings>#</TableHeadings>
                <TableHeadings>Name</TableHeadings>
                <TableHeadings>Action</TableHeadings>
              </TableRow>
            </TableHead>
            <TableBody>
              {getVariantsLoading ? (
                <TableRow>
                  <TableContent sx={{ width: "100%" }}>
                    <MoonLoader color="#000" size={20} />
                  </TableContent>
                </TableRow>
              ) : variantsList.length === 0 ? (
                <TableRow align="center">
                  <TableContent colSpan={4} align="center">
                    You do not have any variants yet!
                  </TableContent>
                </TableRow>
              ) : (
                variantsList.map((variant, index) => (
                  <TableRow key={index}>
                    <TableContent sx={{ width: "1%" }}>{index + 1}</TableContent>
                    <TableContent sx={{ width: "90%" }}>
                      {variant.name} , {variant.arb_name}
                    </TableContent>
                    <TableContent
                      sx={{ display: "flex", gap: "10px", cursor: "pointer" }}
                    >
                      <Tooltip title="edit" placement="top">
                        <EditIcon
                          sx={{ fontSize: "20px", color: "#000" }}
                          onClick={() => editVariant(variant)}
                        />
                      </Tooltip>
                      <Tooltip title="delete" placement="top">
                        <DeleteIcon
                          sx={{ fontSize: "20px", color: "red" }}
                          onClick={() => openConfirmationModal(variant?.uuid)}
                        />
                      </Tooltip>
                    </TableContent>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableWrapper>
      </Wrapper>
    </>
  );
}

const Wrapper = styled(Container)(() => ({
  width: "100%",
  padding: "1rem",
  height: "calc(100vh - 60px)",
  overflow: "auto",
}));

const ControlWrapper = styled(Box)(() => ({
  width: "100%",
  background: "#fff",
  padding: "10px 15px",
  borderRadius: "12px",
  fontSize: "22px",
  fontWeight: "600",
}));

const Heading = styled(Typography)(() => ({
  fontSize: "16px",
  fontWeight: "600",
  color: "#424242",
}));

const Input = styled(TextField)({
  width: "100%",
  background: "#fff",
  borderRadius: "5px",
});

const Label = styled(Typography)(() => ({
  fontSize: "12px",
  fontWeight: "500",
}));

const SaveBTN = styled(Button)(({ theme }) => ({
  width: "120px",
  height: "40px",
  marginTop: "15px",
  fontSize: "16px",
  background: `${theme.palette.primary.main}`,
  color: "#fff",
  borderRadius: "5px",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: `${theme.palette.primary.main}`,
  },
}));

const TableWrapper = styled(TableContainer)(() => ({
  height: "auto",
  overflow: "auto",
  border: "none",
  boxShadow: "none",
  margin: "30px 0",
}));

const TableHeadings = styled(TableCell)(() => ({
  fontWeight: "600",
  fontSize: "14px",
  lineHeight: "16px",
  color: "black",
  background: "#F2F4F5",
}));

const TableContent = styled(TableCell)(() => ({
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "16px",
  color: "#71747D",
  border: "none",
}));

export default Variants;
