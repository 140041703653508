import React, { useState, useEffect } from 'react'
import { styled } from "@mui/material/styles";
import axios from 'axios';

// Loader Import
import { MoonLoader } from 'react-spinners';

// MUI Components Import
import { Container, Box, Grid, Button, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tooltip, TextField } from '@mui/material';

// Components Import
import Header from '../../components/Header';
import WarehouseControl from '../components/WarehouseControl';
import { useLoaderContext } from '../../../../contexts/LoaderContext';
import { baseUrl } from '../../../../constants/api';
import { useFormik } from 'formik';
import * as Yup from 'yup';

// Icons Import
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/BorderColor';
import { toast } from 'react-toastify';
import ConfirmationModal from '../../components/Modals/ConfirmationModal';

const validationSchema = Yup.object({
    name: Yup.string().required('English Name is required'),
    location: Yup.string().required('Location is required'),
});

const headerData = {
    heading: "Configure Warehouses",
    subheading: "",
}

function Warehouses() {

    const token = window.localStorage.getItem("user-token");

    const { loading, handleLoader } = useLoaderContext()

    const [saving, setSaving] = useState(false)
    const [warehousesList, setWarehousesList] = useState([])
    const [editingWarehouse, setEditingWarehouse] = useState(null);
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [uuid, setUUID] = useState("");


    const formik = useFormik({
        enableReinitialze: true,
        initialValues: {
            name: editingWarehouse != null ? editingWarehouse.name : '',
            location: editingWarehouse != null ? editingWarehouse.location : '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (editingWarehouse) {
                handleEdit(editingWarehouse.uuid, values);
            } else {
                handleCreate(values);
            }
        },
    });

    const editWarehouse = (warehouses) => {
        formik.setFieldValue("name", warehouses.name);
        formik.setFieldValue("location", warehouses.location);
        setEditingWarehouse(warehouses);
    }

    const handleCreate = (formValues) => {
        setSaving(true)
        axios.post(`${baseUrl}/api/warehouse/create`,
            {
                name: formValues.name,
                location: formValues.location,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${token}`,
                },
            }
        )
            .then((response) => {
                toast.success("Warehouse created successfully!");
                setTimeout(() => {
                    setSaving(false)
                }, 2000);
                formik.resetForm();
                fetchEntities()
            })
            .catch((err) => {
                toast.error("Failed to create Warehouse!");
                setSaving(false)
            })
    }

    const handleEdit = (id, formValues) => {
        setSaving(true);
        axios.put(`${baseUrl}/api/warehouse/update/${id}`,
            {
                name: formValues.name,
                location: formValues.location,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${token}`,
                },
            }
        )
            .then(() => {
                toast.success("warehouse edited successfully!");
                setTimeout(() => {
                    setSaving(false);
                }, 2000);
                formik.resetForm();
                fetchEntities();
            })
            .catch((err) => {
                if (err.response) {
                    toast.error(err.response.data.message);
                }
                setSaving(false);
            });
    }

    const handleDelete = (id) => {
        axios.delete(`${baseUrl}/api/warehouse/delete/${id}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${token}`,
                },
            }
        )
            .then((response) => {
                toast.success("warehouse deleted successfully!");
                fetchEntities()
                setWarehousesList(response.data.warehouses)
            })
            .catch((error) => {
                if (error.response) {
                    toast.error(error.response.data.message);
                }
            })
    }


    const fetchEntities = async () => {
        handleLoader(true)
        axios.get(`${baseUrl}/api/entities`,
            {
                headers: {
                    "Content-Type": "text/plain",
                    Authorization: `Token ${token}`,
                },
            }
        )
            .then((response) => {
                setWarehousesList(response.data.warehouses)
                handleLoader(false)
            })
            .catch((error) => {
                console.log("Error", error)
                handleLoader(false)
            })
    }

    useEffect(() => {
        fetchEntities()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const openConfirmationModal = (id) => {
        setUUID(id);
        setConfirmationModal(true);
    };

    return (
        <>
            {uuid ? (
                <ConfirmationModal
                    prodId={uuid}
                    open={confirmationModal}
                    setOpen={setConfirmationModal}
                    heading={"Confirm Warehouse Deletion"}
                    text={"Are you sure you want to delete the Warehouse?"}
                    handleDeleteProduct={handleDelete}
                />
            ) : null}
            <Wrapper maxWidth={false}>
                <Header data={headerData} />
                <ControlWrapper mt={3}>
                    <Heading>Create a Warehouse</Heading>
                    <Grid container mt={1} spacing={3} display={"flex"} alignItems={"center"} >
                        <Grid item md={4}>
                            <Label>Warehouse Name</Label>
                            <Input name='name'
                                {...formik.getFieldProps('name')}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}

                            />
                        </Grid>
                        <Grid item md={4}>
                            <Label>Warehouse Location</Label>
                            <Input name='location'
                                {...formik.getFieldProps('location')}
                                error={formik.touched.location && Boolean(formik.errors.location)}
                                helperText={formik.touched.location && formik.errors.location}

                            />
                        </Grid>
                        <Grid item md={4}>
                            <SaveBTN onClick={() => formik.handleSubmit()}>
                                {saving ? (
                                    <>
                                        <MoonLoader color="#fff" size={20} />
                                    </>
                                ) : (
                                    editingWarehouse ? "Update" : "Create"
                                )}
                            </SaveBTN>

                        </Grid>
                    </Grid>
                </ControlWrapper>
                <TableWrapper component={Paper}>
                    <Table>
                        <TableHead style={{ backgroundColor: '#DDDDDD' }}>
                            <TableRow>
                                <TableHeadings>#</TableHeadings>
                                <TableHeadings>Name</TableHeadings>
                                <TableHeadings>Location</TableHeadings>
                                <TableHeadings>Action</TableHeadings>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <TableRow>
                                    <TableContent sx={{ width: "100%", }} >
                                        <MoonLoader color="#000" size={20} />
                                    </TableContent>
                                </TableRow>
                            ) : (
                                warehousesList.length === 0 ? (
                                    <TableRow align="center">
                                        <TableContent colSpan={4} align="center">
                                            You do not have any Warehouse yet!
                                        </TableContent>
                                    </TableRow>
                                ) : (
                                    warehousesList.map((warehouse, index) => (
                                        <TableRow key={index}>
                                            <TableContent sx={{ width: "1%" }} >{index + 1}</TableContent>
                                            <TableContent sx={{ width: "20%" }}>
                                                {warehouse.name}
                                            </TableContent>
                                            <TableContent>
                                                {warehouse.location}
                                            </TableContent>
                                            <TableContent sx={{ display: "flex", gap: "10px", cursor: "pointer" }}>
                                                <Tooltip title="edit" placement='top' >
                                                    <EditIcon sx={{ fontSize: "20px", color: "#000" }} onClick={() => editWarehouse(warehouse)} />
                                                </Tooltip>
                                                <Tooltip title="delete" placement='top' >
                                                    <DeleteIcon sx={{ fontSize: "20px", color: "red" }}
                                                        onClick={() => openConfirmationModal(warehouse?.uuid)} />
                                                </Tooltip>
                                            </TableContent>

                                        </TableRow>
                                    ))
                                )
                            )
                            }
                        </TableBody>
                    </Table>
                </TableWrapper>
            </Wrapper>
        </>
    )
}

const Wrapper = styled(Container)(() => ({
    width: "100%",
    padding: '1rem',
    height: "calc(100vh - 60px)",
    overflow: "auto"
}))

const ControlWrapper = styled(Box)(() => ({
    width: "100%",
    background: "#fff",
    padding: "10px 15px",
    borderRadius: "12px",
    fontSize: "22px",
    fontWeight: "600"
}))

const Heading = styled(Typography)(() => ({
    fontSize: "16px",
    fontWeight: "600",
    color: "#424242"
}))

const Input = styled(TextField)(({
    width: "100%",
    background: "#fff",
    borderRadius: "5px"
}))

const Label = styled(Typography)(() => ({
    fontSize: "12px",
    fontWeight: "500"
}))

const SaveBTN = styled(Button)(({ theme }) => ({
    width: '120px',
    height: '40px',
    marginTop: "15px",
    fontSize: "16px",
    float: "right",
    background: `${theme.palette.primary.main}`,
    color: "#fff",
    borderRadius: '5px',
    textTransform: "capitalize",
    "&:hover": {
        backgroundColor: `${theme.palette.primary.main}`
    }
}))

const TableWrapper = styled(TableContainer)(() => ({
    height: "auto",
    overflow: "auto",
    border: "none",
    boxShadow: "none",
    margin: "30px 0"
}))

const TableHeadings = styled(TableCell)(() => ({
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "16px",
    color: "black",
    background: "#F2F4F5"
}))

const TableContent = styled(TableCell)(() => ({
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "16px",
    color: "#71747D",
    border: 'none'
}))

export default Warehouses