import { styled } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useUserThemeContext } from "../../../contexts/ThemeContext";
import "../../styles.css";

// MUI Components Import
import { Box } from "@mui/material";

// Pro Sidebar Components Import
import { Menu, MenuItem, Sidebar, SubMenu } from "react-pro-sidebar";

// Assets Import
import logo from "../../../assets/logo_grey.webp";

// MUI Icons Import
import InventoryIcon from "@mui/icons-material/DashboardOutlined";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import axios from "axios";
import { baseUrl } from "../../../constants/api";

function SideBar() {
	const { navTheme } = useUserThemeContext();
	const colorRef = useRef();
	const navigate = useNavigate();
	const location = useLocation();
	const current_loc = location.pathname.split("/").pop();

	const token = window.localStorage.getItem("user-token");

	// const marketplaceRoute = "https://ai3rp-marketplace-stag.web.app/";

	// const marketRoute = () => {
	//   window.open(marketplaceRoute, "_blank");
	// };

	// eslint-disable-next-line no-unused-vars
	const [logoData, setLogoData] = useState([]);

	const fetchLogo = () => {
		axios
			.get(`${baseUrl}/api/auth/settings`, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Token ${token}`,
				},
			})
			.then((response) => {
				setLogoData(response.data.logo);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		fetchLogo();
		colorRef.current.style.backgroundColor = navTheme;
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [navTheme]);

	return (
		<>
			<SidebarWrapper
				ref={colorRef}
				sx={{ backgroundColor: `${navTheme}`, height: "100vh" }}
			>
				<img src={logo} alt='logo' style={{ maxWidth: "100%", height: "100px", marginTop: "10px" }} />
				<Sidebar
					style={{
						width: "100%",
						minWidth: "250px",
						marginTop: "20px",
						border: "none",
						color: "#fff",
						background: "none",
					}}
				>
					<Menu style={{ background: "none" }}>
						<SubMenu icon={<InventoryIcon />} label="Inventory Management">
							<MenuItem
								onClick={() => navigate("")}
								className={current_loc === "dashboard" && "activeSide"}
							>
								Dashboard
							</MenuItem>
							<MenuItem
								onClick={() => navigate("products-list")}
								className={current_loc === "products-list" && "activeSide"}
							>
								Products
							</MenuItem>
							{/* <MenuItem
                onClick={() => navigate("add-product")}
                className={current_loc === "add-product" && "activeSide"}
              >
                Add Product
              </MenuItem> */}
							<MenuItem
								onClick={() => navigate("bulk")}
								className={current_loc === "bulk" && "activeSide"}
							>
								Upload Products
							</MenuItem>

							<MenuItem
								onClick={() => navigate("reviews")}
								className={current_loc === "reviews" && "activeSide"}
							>
								Reviews
							</MenuItem>
						</SubMenu>
						<SubMenu icon={<InventoryIcon />} label="Orders Management">
							<MenuItem
								onClick={() => navigate("sales/orders-list")}
								className={current_loc === "orders-list" && "activeSide"}
							>
								Order Listing
							</MenuItem>
							<MenuItem
								onClick={() => navigate("sales/refunds-list")}
								className={current_loc === "refunds-list" && "activeSide"}
							>
								Refunds Listing
							</MenuItem>
						</SubMenu>
						{/* Service Provider */}
						{/* <SubMenu icon={<SettingsIcon />} label="Services Management">
              <MenuItem
                onClick={() => navigate("services")}
                className={current_loc === "services" && "activeSide"}
              >
                Services
              </MenuItem>
            </SubMenu> */}
						<SubMenu icon={<InventoryIcon />} label="Customers">
							<MenuItem
								onClick={() => navigate("customers/customer-list")}
								className={current_loc === "customer-list" && "activeSide"}
							>
								Customers
							</MenuItem>
						</SubMenu>
						<SubMenu icon={<SettingsIcon />} label="Controls">
							<MenuItem
								onClick={() => navigate("controls/brands")}
								className={current_loc === "brands" && "activeSide"}
							>
								Brands
							</MenuItem>
							<MenuItem
								onClick={() => navigate("controls/models")}
								className={current_loc === "models" && "activeSide"}
							>
								Models
							</MenuItem>
							<MenuItem
								onClick={() => navigate("controls/categories")}
								className={current_loc === "categories" && "activeSide"}
							>
								Categories
							</MenuItem>
							<MenuItem
								onClick={() => navigate("controls/subcategories")}
								className={current_loc === "subcategories" && "activeSide"}
							>
								Subcategories
							</MenuItem>
							<MenuItem
								onClick={() => navigate("controls/warehouses")}
								className={current_loc === "warehouses" && "activeSide"}
							>
								Warehouses
							</MenuItem>
							<MenuItem
								onClick={() => navigate("/dashboard/controls/units")}
								className={current_loc === "units" && "activeSide"}
							>
								Units
							</MenuItem>
							<MenuItem
								onClick={() => navigate("controls/variants")}
								className={current_loc === "variants" && "activeSide"}
							>
								Variants
							</MenuItem>
							<MenuItem
								onClick={() => navigate("controls/testimonials")}
								className={current_loc === "testimonials" && "activeSide"}
							>
								Testimonials
							</MenuItem>
							{/* <MenuItem
                onClick={() => navigate("controls/service-category")}
                className={current_loc === "service-category" && "activeSide"}
              >
                Service Category
              </MenuItem>

              <MenuItem
                onClick={() => navigate("controls/service-type")}
                className={current_loc === "service-type" && "activeSide"}
              >
                Service Type
              </MenuItem>

              <MenuItem
                onClick={() => navigate("controls/charge-type")}
                className={current_loc === "charge-type" && "activeSide"}
              >
                Charge Type
              </MenuItem>
              <MenuItem
                onClick={() => navigate("controls/provider-type")}
                className={current_loc === "provider-type" && "activeSide"}
              >
                Provider Type
              </MenuItem> */}
						</SubMenu>
						<SubMenu icon={<SettingsIcon />} label="Settings">
							<MenuItem
								onClick={() => navigate("settings/banners")}
								className={current_loc === "banners" && "activeSide"}
							>
								Add Banners
							</MenuItem>
							<MenuItem
								onClick={() => navigate("settings/config")}
								className={current_loc === "config" && "activeSide"}
							>
								Configurations
							</MenuItem>
							<MenuItem
								onClick={() => navigate("settings/theme")}
								className={current_loc === "theme" && "activeSide"}
							>
								Theme
							</MenuItem>
							<MenuItem
								onClick={() => navigate("settings/footer")}
								className={current_loc === "footer" && "activeSide"}
							>
								Footer
							</MenuItem>
						</SubMenu>
					</Menu>
				</Sidebar>
			</SidebarWrapper>
		</>
	);
}

// Styled Components

const SidebarWrapper = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	width: "300px",
	height: "auto",
	border: "none",
	transition: "all 300ms ease",
	overflow: "auto",
}));

export default SideBar;
