import React from "react";
import { styled } from "@mui/material/styles";

// MUI Components Import
import { Box, Typography } from "@mui/material";

// Recharts Components Import
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

// MUI Icons Import
import FilterListIcon from "@mui/icons-material/FilterList";

const data = [
    {
        month: 'Jan',
        applied: 212,
        shortlisted: 150,
        rejected: 62,
    },
    {
        month: 'Feb',
        applied: 158,
        shortlisted: 98,
        rejected: 60,
    },
    {
        month: 'Mar',
        applied: 318,
        shortlisted: 122,
        rejected: 196,
    },
    {
        month: 'Apr',
        applied: 540,
        shortlisted: 415,
        rejected: 125,
    },
    {
        month: 'May',
        applied: 80,
        shortlisted: 35,
        rejected: 45,
    },
    {
        month: 'June',
        applied: 115,
        shortlisted: 18,
        rejected: 97,
    },
    {
        month: 'July',
        applied: 40,
        shortlisted: 8,
        rejected: 32,
    },
    {
        month: 'Aug',
        applied: 70,
        shortlisted: 45,
        rejected: 25,
    },
];

const FilterButton = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "4px",
  padding: "4px 10px",
  borderRadius: "4px",
  border: "1px solid #ddd",
});

const StatsGraph = () => {
  return (
    <Box sx={{background: "#fff"}} border={"1px solid #ddd"} borderRadius={"10px"} padding={"16px 20px"}>
      <Box
        mt={1}
        mb={2}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography fontWeight={"600"} fontSize={"18px"}>
          Applicant Stats
        </Typography>
        <Box alignItems={"center"} display={"flex"} gap={"20px"}>
          <Box alignItems={"center"} display={"flex"} gap={"10px"}>
            <Box height={10} width={10} borderRadius={5} backgroundColor={"#812990"} />
            <Typography fontSize={"10px"} fontWeight={"300"}>
              Applied
            </Typography>
          </Box>
          <Box alignItems={"center"} display={"flex"} gap={"10px"}>
            <Box height={10} width={10} borderRadius={5} backgroundColor={"#922785"} />
            <Typography fontSize={"10px"} fontWeight={"300"}>
              Shortlisted
            </Typography>
          </Box>
          <Box alignItems={"center"} display={"flex"} gap={"10px"}>
            <Box height={10} width={10} borderRadius={5} backgroundColor={"#EA0216"} />
            <Typography fontSize={"10px"} fontWeight={"300"}>
              Rejected
            </Typography>
          </Box>
          <FilterButton>
            <FilterListIcon fontSize="12px" />
            <Typography fontSize={"12px"}>Filter</Typography>
          </FilterButton>
        </Box>
      </Box>
      <Box width={"100%"} height={"196px"}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          barSize={10}
          height={300}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" tick={{fontSize: "15px"}} />
          <YAxis tick={{fontSize: "15px"}} />
          <Tooltip />
          <Bar barSize={15} dataKey="applied" stackId="a" fill="#812990" />
          <Bar barSize={15} dataKey="shortlisted" stackId="a" fill="#922785" />
          <Bar barSize={15} dataKey="rejected" stackId="a" fill="#EA0216" />
        </BarChart>
      </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export default StatsGraph;