import React from 'react'
import { styled } from "@mui/material/styles";

// MUI Components Import
import { Box, Container, Grid, Button } from "@mui/material";

// MUI Icons Import
import AddIcon from '@mui/icons-material/AddOutlined';

// Components Import
import Header from "../components/Header";
import DashboardCards from '../InventoryDash/components/DashboardCards';
import StatsGraph from './components/StatsGraph';
import StatsTable from './components/StatsTable';
import RecentJobs from './components/RecentJobs';
import Meetings from './components/Meetings';

// Assets Import
import iconOne from '../../../assets/crm-icon-one.webp'
import iconTwo from '../../../assets/crm-icon-two.webp'
import iconThree from '../../../assets/crm-icon-three.webp'
import iconFour from '../../../assets/crm-icon-four.webp'

const headerData = {
    heading: "Human Resource",
    subheading: "Empower your workforce with our HR dashboard's real-time insights and analytics"
}

const cardData = [
    {
        backgroundColor: "#FFF2E5",
        icon: iconOne,
        heading: "Total Applications",
        number: "840",
    },
    {
        backgroundColor: "#FFDFF6",
        icon: iconTwo,
        heading: "Shortlisted Candidates",
        number: "592",
    },
    {
        backgroundColor: "#DDFFE7",
        icon: iconThree,
        heading: "Rejected Candidates",
        number: "300",
    },
    {
        backgroundColor: "#FFEBEA",
        icon: iconFour,
        heading: "Total Staff",
        number: "04",
    },
];

function Dashboard() {
    return (
            <Wrapper maxWidth={false}>
                <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <Header data={headerData} />
                    <Button variant="contained" color="secondary" startIcon={<AddIcon />}>
                        Add Job
                    </Button>
                </Box>
                <DashboardCards data={cardData} />
                <Grid container mt={2} gap={2} justifyContent={"space-between"}>
                    {/* Applicant Stats */}
                    <Card item xs={8.2} gap={3}>
                        <StatsGraph/>
                        <StatsTable/>
                    </Card>
                    {/* Recent Jobs + Meetings */}
                    <Card item xs={3.6} gap={3}>
                        <RecentJobs/>
                        <Meetings/>
                    </Card>
                </Grid>
            </Wrapper>
    )
}

// Styled Components

const Wrapper = styled(Container)(() => ({
    padding: "1rem",
	width: "100%",
	height: "calc(100vh - 60px)",
    overflow: "auto"
})) 

const Card = styled(Grid)(() => ({
    display: "flex",
    flexDirection: "column",
    borderRadius: "14px"
}))

export default Dashboard