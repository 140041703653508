import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import axios from "axios";
// MUI Components Import
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  Checkbox,
} from "@mui/material";
// Loader Import
import { MoonLoader } from "react-spinners";
// React Toastify Imports
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseUrl } from "../../../../constants/api";
import { CodeBlock, atomOneLight } from "react-code-blocks";

function MetaPixel() {
  const token = window.localStorage.getItem("user-token");

  const [saving, setSaving] = useState(false);
  const [tax, setTax] = useState();
  const [isActive, setIsActive] = useState(false);

  const handleUpdate = async () => {
    if (!tax) {
      toast.error("MetaPixel key is required!");
      return;
    }
    setSaving(true);
    try {
      await axios.put(
        `${baseUrl}/api/auth/settings`,
        {
          meta_pixel: tax,
          meta_pixel_active: isActive,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );
      toast.success("MetaPixel updated successfully!");
      setTimeout(() => {
        setSaving(false);
      }, 2000);
      fetchTax();
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.message);
      }
      setSaving(false);
    }
  };

  const fetchTax = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/auth/settings`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      });
      setTax(response?.data?.meta_pixel);
      setIsActive(response?.data?.meta_pixel_active);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchTax();
  }, []);

  const script = `<script>
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '${tax}');
      fbq('track', 'PageView');
    </script>`;

  return (
    <>
      <Wrapper mt={3}>
        <Heading>MetaPixel Script</Heading>
        <Grid container mt={1}>
          <Grid item md={6}>
            <Box
              sx={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                }}
              >
                <Label>Add Meta Script Key</Label>
                <Input
                  placeholder="e.g. 1234567890"
                  value={tax}
                  onChange={(e) => setTax(e.target.value)}
                />
              </Box>
              <Box>
                <Label>Active</Label>
                <Checkbox
                  type="checkbox"
                  checked={isActive}
                  onChange={(e) => setIsActive(e.target.checked)}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item md={6}>
            <Box
              sx={{
                marginLeft: "50px",
              }}
            >
              <CodeBlock
                text={script}
                theme={atomOneLight}
                highlight="10"
                customStyle={{
                  height: "260px",
                  borderRadius: "5px",
                  boxShadow: "1px 2px 3px rgba(0,0,0,0.35)",
                  fontSize: "12px",
                  margin: "0px 0.75rem",
                }}
              />
            </Box>
          </Grid>
          <Grid
            item
            md={12}
            sx={{
              margin: "20px 0",
            }}
          >
            <SaveBTN onClick={() => handleUpdate()}>
              {saving ? (
                <>
                  <MoonLoader color="#fff" size={20} />
                </>
              ) : (
                "Update"
              )}
            </SaveBTN>
          </Grid>
        </Grid>
      </Wrapper>
    </>
  );
}

const Wrapper = styled(Box)(() => ({
  width: "100%",
  background: "#fff",
  padding: "10px 15px",
  borderRadius: "12px",
  fontSize: "22px",
  fontWeight: "600",
}));

const Input = styled(TextField)({
  width: "100%",
  background: "#fff",
  borderRadius: "5px",
});

const Label = styled(Typography)(() => ({
  fontSize: "12px",
  fontWeight: "500",
}));

const SaveBTN = styled(Button)(({ theme }) => ({
  width: "120px",
  height: "40px",
  marginTop: "15px",
  fontSize: "16px",
  float: "right",
  background: `${theme.palette.primary.main}`,
  color: "#fff",
  borderRadius: "5px",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: `${theme.palette.primary.main}`,
  },
}));

const Heading = styled(Typography)(() => ({
  fontSize: "16px",
  fontWeight: "600",
  color: "#424242",
}));

export default MetaPixel;
