import React from "react";
import { styled } from "@mui/material/styles";

// MUI Components Import
import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";

// Assets Import
import AvatarOne from "../../../../assets/avatars/avatar-one.webp";
import AvatarTwo from "../../../../assets/avatars/avatar-six.webp";
import AvatarThree from "../../../../assets/avatars/avatar-three.webp";
import AvatarFour from "../../../../assets/avatars/avatar-two.webp";
import AvatarFive from "../../../../assets/avatars/avatar-four.webp";


const statData = [
    {
        name: "Abdul Saboor",
        img: AvatarOne,
        jobTitle: "Store Manager",
        status: "Received",
        time: "1 Hour ago",
    },
    {
        name: "Ghazi Muaz",
        img: AvatarTwo,
        jobTitle: "Staff Engineer",
        status: "Accepted",
        time: "02 Days ago",
    },
    {
        name: "Mahnoor",
        img: AvatarThree,
        jobTitle: "Designer",
        status: "Applied",
        time: "03 Hours ago",
    },
    {
        name: "Amir Ali",
        img: AvatarFour,
        jobTitle: "Store Incharge",
        status: "Accepted",
        time: "01 Day ago",
    },
    {
        name: "Haider Ali",
        img: AvatarFive,
        jobTitle: "Finance Manager",
        status: "Received",
        time: "06 Hours ago",
    },
];

const StatItem = styled(Box)({
  display: "flex",
  alignItems: "center",
  margin: "10px 0px",
  borderBottom:"1px solid #e5e5e5",
  paddingBottom:"8px"
});

const StatsTable = () => {
  return (
    <Box sx={{background: "#fff"}} padding={"10px 20px"} border={"1px solid #ddd"} borderRadius={"10px"}>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography fontWeight={"600"} my={2} fontSize={18}>
          Applicant Stats
        </Typography>
        <FormControl size="small" style={{ width: "140px" }}>
          <InputLabel size="small" id="demo-simple-select-label">All Activity</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={null}
            size="small"
            label="activity"
            onChange={() => {}}
          >
            <MenuItem value={10}>Activity 1</MenuItem>
            <MenuItem value={20}>Activity 2</MenuItem>
            <MenuItem value={30}>Activity 3</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box mt={2}>
        {statData.map((stat) => {
          return (
            <StatItem>
              <Box flex={1} gap={"6px"} display={"flex"}>
                <img
                  src={stat.img}
                  height={"40px"}
                  width={"40px"}
                  style={{ borderRadius: "20px" }}
                  alt="avatar"
                />
                <Box flexDirection={"column"}>
                  <Box gap={"6px"}>
                    <Typography component={"span"} fontSize={"12px"} fontWeight={"600"}>{stat.name} </Typography>
                    <Typography component={"span"} fontSize={"12px"} color={"#A4A4A4"}>
                       recently applied for the job
                    </Typography>
                    <Typography component={"span"} fontSize={"12px"} fontWeight={"600"}> {stat.jobTitle}</Typography>
                  </Box>
                  <Typography
                    fontWeight={"400"}
                    color={"#A4A4A4"}
                    fontSize={"12px"}
                  >
                    {stat.time}
                  </Typography>
                </Box>
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                width={"180px"}
              >
                <Box
                  borderRadius={"4px"}
                  padding={"6px 14px"}
                  backgroundColor={"rgba(1, 191, 77,.1)"}
                >
                  <Typography fontWeight={"600"} fontSize={"10px"} color={"#812990"}>
                  {stat.status}
                  </Typography>
                </Box>
              </Box>
            </StatItem>
          );
        })}
      </Box>
    </Box>
  );
};

export default StatsTable;
