import React from 'react'
import { styled } from "@mui/material/styles";

// MUI Components Import
import { Box, Typography, Button, FormControl, FormGroup, FormControlLabel, Checkbox, FormHelperText } from '@mui/material';

function PaymentSelect() {

    const [state, setState] = React.useState({
        cod: true,
        card: false,
        paylater: false,
    });
    
    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        });
    };
    
    const { cod, card, paylater } = state;
    const error = [cod, card, paylater].filter((v) => v).length === 0;
    
    return (
        <>
            <Wrapper mt={3}>
                <Heading>Choose payment methods</Heading>
                <FormControl sx={{mt: 3}} required error={error} component="fieldset" variant="standard">
                    <FormGroup row>
                        <FormControlLabel label="Cash on Delivery"
                            control={
                                <Checkbox checked={cod} onChange={handleChange} name="cod" />
                            }
                        />
                        <FormControlLabel label="Card Payment"
                            control={
                                <Checkbox checked={card} onChange={handleChange} name="card" />
                            }
                        />
                        <FormControlLabel label="Pay Later"
                            control={
                            <Checkbox checked={paylater} onChange={handleChange} name="paylater" />
                            }
                        />
                    </FormGroup>
                    {error && <FormHelperText>One must be selected</FormHelperText>}
                </FormControl>
                <SaveBTN>Save</SaveBTN>
            </Wrapper>
        </>
    )
}

const Wrapper = styled(Box)(() => ({
    width: "100%",
    background: "#fff",
    padding: "10px 15px",
    borderRadius: "12px",
    fontSize: "22px",
    fontWeight: "600"
}))

const Heading = styled(Typography)(() => ({
    fontSize: "16px",
    fontWeight: "600",
    color: "#424242"
}))

const SaveBTN = styled(Button)(({theme}) => ({
    width: '120px', 
    height: '40px', 
    marginTop: "15px",
    fontSize: "16px",
    float: "right",
    background: `${theme.palette.primary.main}`, 
    color: "#fff", 
    borderRadius: '5px',
    textTransform: "capitalize",
    "&:hover": {
        backgroundColor: `${theme.palette.primary.main}`
    }
})) 

export default PaymentSelect